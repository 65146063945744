import { enumToValues } from "@ntropy/utils/src/enum-utils";
import { createEnumParam } from "serialize-query-params";
import { WalletCurrency, CryptoCurrency } from "src/core/currency/currency.model";
import { ISODateTimeString, Guid } from "src/utils/type-utils";

export enum TransactionType {
    Deposit = "deposit",
    Withdrawal = "withdrawal",
}

export enum TransactionTypeId {
    Deposit = 1,
    Withdrawal = 2,
}

export const TransactionTypeParam = createEnumParam(enumToValues(TransactionType));

export enum TransactionStatus {
    Pending = "pending",
    Completed = "completed",
    Error = "error",
}

export type HandcashTransactionTask = "DEPOSIT" | "WITHDRAW" | "PENALTY";

export interface IHandcashTransactionEntry {
    txid: string;
    status: string;
    timestamp: number;
    amount: number;
    task: HandcashTransactionTask;
}

export interface IHandcashTransactionsResponse {
    timestamp: number;
    data: IHandcashTransactionEntry[]
}

export interface ICashierTransactionEntry {
    publicId: Guid
    amount: number
    currencyTicker: WalletCurrency
    amountInUsd: number
    createTime: ISODateTimeString
    status: TransactionStatus
    type: TransactionTypeId
}

export interface ITransactionEntry {
    transactionId: string
    amount: number
    currency: CryptoCurrency
    amountInUsd?: number
    timeFormatted: string
    timestampParam: ISODateTimeString | number
    status: string
    type: string
}