import { useQuerySignalRUpdate } from "src/core/signalr/hooks/useQuerySignalRUpdate";
import { SignalREvent } from "src/core/signalr/signalr.model";
import { walletAccountsQueryKey } from "src/core/wallet/hooks/useWalletAccounts/useWalletAccounts.query";
import { ICurrencyAccount, WalletCurrency } from "src/core/currency/currency.model";
import { useAuthorizedState } from "src/core/auth/hooks/useAuthorizedState";
import { WalletProvider } from "src/core/wallet/wallet.model";

export function useWalletAccountsSubscription() {
    const { isAuthorized } = useAuthorizedState(WalletProvider.WalletConnect);

    useQuerySignalRUpdate<[WalletCurrency, number], ICurrencyAccount[]>(
        walletAccountsQueryKey,
        SignalREvent.BalanceModified,
        (eventData, queryData) => {
            if (!queryData) {
                return;
            }

            const [currencyTicker, balance] = eventData;

            return [
                ...queryData.filter(g => g.currencyTicker !== currencyTicker),
                { currencyTicker, balance },
            ]
        }, { enabled: isAuthorized });
}