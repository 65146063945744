import { useAuthorizedCookie } from "src/core/auth/hooks/useAuthorizedCookie";
import { useWalletProvider } from "src/core/wallet/hooks/useWalletProvider";
import { WalletProvider } from "src/core/wallet/wallet.model";
import { useSessionToken } from "src/core/auth/hooks/useSessionToken";

export function useAuthorizedState(provider?: WalletProvider) {
    const [authorized] = useAuthorizedCookie();
    const [walletProvider] = useWalletProvider();
    const [token] = useSessionToken();

    let isAuthorized = provider ? authorized && walletProvider === provider : authorized;
    let isAuthorizing = provider ? !authorized && walletProvider === provider : !authorized && !!walletProvider;

    if (walletProvider === WalletProvider.WalletConnect && !token && isAuthorized) { // in case of WalletConnect, we also require token to be present
        isAuthorizing = true;
        isAuthorized = false;
    }

    return {
        isAuthorized,
        walletProvider,
        isAuthorizing,
    }
}