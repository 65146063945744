import { useCallback } from "react";
import debounce from "lodash/debounce";
// eslint-disable-next-line no-restricted-imports
import type { DebounceSettings, DebouncedFunc } from "lodash";
import { CallbackFn } from "@ntropy/utils";
import { useRefValue } from "@ntropy/hooks/src/state/useRefValue";

export { DebounceSettings };

export function useDebounced<T extends any[] = any[], R = void>(
    fn: CallbackFn<T, R> | null | undefined,
    wait: number,
    options: DebounceSettings = { leading: false, trailing: true },
): DebouncedFunc<CallbackFn<T, R>> {
    const callbackRef = useRefValue(fn);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const callback = useCallback((...args: T) => callbackRef.current?.(...args), []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(debounce(callback, wait, options), [wait]);
}