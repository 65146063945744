import {useEffect, useRef, useState} from "react";
import BigNumber from "bignumber.js";
import classNames from "classnames/bind";
import styles from "../assets/styles.scss";

const cx = classNames.bind(styles);

export default function useBettingAmount(
    bettingAmount,
    minimum,
    maximum,
    defaultBet,
    decimalPlaces,
    onChange,
    localStorageKey
) {
    const [inputValue, setInputValue] = useState(bettingAmount.toFixed());
    const inputRef = useRef();

    function updateBettingAmount(newAmount) {
        if (newAmount.isNaN()) {
            setInputValue(bettingAmount.toString());
            return;
        }

        if (newAmount.isGreaterThan(maximum)) {
            newAmount = new BigNumber(maximum);
        } else if (newAmount.isLessThan(minimum)) {
            newAmount = new BigNumber(minimum);
        }

        if (decimalPlaces) {
            newAmount = newAmount.decimalPlaces(decimalPlaces);
        }
        onChange(newAmount);
        setInputValue(newAmount.toString());
        localStorage.setItem(localStorageKey, newAmount.toString());
    }

    function onKeyDown(e) {
        // save value with enter key
        if (e.key === "Enter") {
            updateBettingAmount(new BigNumber(e.target.value));
            inputRef.current.blur();
        }
    }

    useEffect(() => {
        if (bettingAmount.isGreaterThan(maximum)) {
            updateBettingAmount(new BigNumber(maximum));
        }
    }, [maximum]);

    useEffect(() => {
        if (defaultBet) {
            const savedValue = localStorage.getItem(localStorageKey);
            if (savedValue) {
                updateBettingAmount(new BigNumber(savedValue));
            } else {
                updateBettingAmount(new BigNumber(defaultBet));
            }
        }
    }, [defaultBet]);

    const inputElement = (
        <input
            className={cx("large", "bold", "reset-style")}
            ref={inputRef}
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            onBlur={() => updateBettingAmount(new BigNumber(inputValue))}
            onKeyDown={onKeyDown}
        />
    );

    return {updateBettingAmount, inputElement};
}
