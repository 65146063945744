import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import { games } from "src/games/config";
import CurrencyIcon from "src/components/currency-icon/CurrencyIcon";

import { IHandcashGameHistoryEntry } from "src/containers/Account/hooks/useGameHistory/game-history.model";
import { GameCode } from "src/core/casino/casino.model";

interface IMyBetsRowProps {
    result: IHandcashGameHistoryEntry;
    gameCode: GameCode | null;
    className: string;
}

const cx = classNames.bind(styles);
export default function MyBetsRow({
    result: {
        game_id: gameId,
        area,
        amount,
        balance: betAmount,
        asset,
        multiplier,
        winning_rate: payoutRate,
        payout,
        payout_balance: payoutBalance,
        winner,
        winner_player: isWin,
        game_result: result,
    },
    gameCode,
    className,
}: IMyBetsRowProps) {
    let formattedPayoutRate: string;

    if (payoutRate) {
        formattedPayoutRate = `${payoutRate?.toFixed(2)}x`;
    } else if (multiplier && winner) {
        formattedPayoutRate = `${Number(multiplier)?.toFixed(2)}x`;
    } else {
        formattedPayoutRate = "0.00x";
    }

    const formattedArea = games[gameCode || ""].formatPick?.(area);
    const formattedResult = games[gameCode || ""].formatResult?.(result);
    const formattedPayout = payoutBalance || payout;
    const formattedBetAmount = betAmount || amount;
    const formattedIsWin = isWin === "Y" || winner;

    return (
        <tr key={gameId} className={cx("small", className)}>
            <td>{gameId || "-"}</td>
            <td className={cx("bet-amount")}>
                <div className="flex items-center justify-center">
                    <span className={cx("small")}>{betAmount || amount}</span>
                    <CurrencyIcon currency={asset} />
                </div>
            </td>
            <td className={cx("hide-mobile")}>{formattedPayoutRate}</td>
            <td className={cx("hide-mobile")}>{formattedArea}</td>
            <td className={cx("hide-mobile")}>{formattedResult || "-"}</td>
            <td className={cx(formattedIsWin ? "win" : "loss", "bold")}>
                {formattedIsWin ? formattedPayout : -formattedBetAmount}
            </td>
        </tr>
    );
}


