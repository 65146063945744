import { FiatCurrency, HandcashCurrency } from "src/core/currency/currency.model";
import { createEnumParam } from "serialize-query-params";
import { enumToValues } from "@ntropy/utils/src/enum-utils";
import { CasinoGameCategory } from "src/core/casino/casino.model";

export enum GameHistoryStatus {
    Completed = "completed",
    Pending = "pending",
    Fail = "fail",
}

export const GameHistoryStatusParam = createEnumParam([...enumToValues(GameHistoryStatus), ""]);

export interface IHandcashGameHistoryEntry {
    game_id: number;
    area: string;
    amount: number;
    balance: number;
    asset: HandcashCurrency;
    multiplier: string | number;
    payout: number;
    payout_balance: number | null;
    winning_rate: number | null;
    winner: boolean;
    winner_player: "Y" | "N" | null;
    game_result: string;
    game: string;
    player_id: number;
    id: number;
    status: GameHistoryStatus | "waiting";
    txid: string;
    payout_txid: string | null;
    txid_for_url: string;
    game_name: string;
}

export interface IHandcashGameHistoryResponse {
    timestamp: number;
    data: IHandcashGameHistoryEntry[]
}

export interface ICashierGameHistoryEntry {
    wagerId: number // our ID
    gameId: string // softswiss GameId ex. softswiss:AlienFruits
    title: string
    provider: string
    category: CasinoGameCategory
    bet: number
    payout: number
    multiplier: number
    transactionId: number
    payoutTransactionId: number
}

export type HandcashTransactionDetails = {
    txId: string;
    payoutTxId: string | null;
    txIdForUrl: string;
}

export type CashierTransactionDetails = {
    transactionId: number;
    payoutTransactionId: number | null;
}

export type TransactionDetails = CashierTransactionDetails | HandcashTransactionDetails;

export const isCashierTransactionDetails = (transaction: TransactionDetails | null | undefined): transaction is CashierTransactionDetails => {
    return !!transaction && "transactionId" in transaction;
}
export const isHandcashTransactionDetails = (transaction: TransactionDetails | null | undefined): transaction is HandcashTransactionDetails => {
    return !!transaction && "txId" in transaction;
}

export type GameHistoryEntry = {
    id: number
    roundId: number | string
    gameName: string
    betAmount: number
    currency: HandcashCurrency | FiatCurrency.USD
    multiplier: number | null
    payout: number | null
    isWin: boolean
    /** only for Cashier */  gameCode?: CasinoGameCategory;
    /** only for Handcash */ status?: GameHistoryStatus;
    /** only for Handcash */ transaction?: TransactionDetails
    /** only for Handcash */ result?: string
    /** only for Handcash */ area?: string
}