import create from "zustand";
import { devtools } from "zustand/middleware";
import BigNumber from "bignumber.js";
import { axiosInstance } from "src/core/http/axios-instance";
import { IWalletBalanceResponse, WalletAssetBalance } from "src/core/wallet/wallet.model";
import { CryptoCurrency } from "src/core/currency/currency.model";
import { isSet } from "@ntropy/utils/src/type-utils";

export interface IAsset {
    selected: CryptoCurrency;
    balance: Partial<Record<CryptoCurrency, BigNumber>>;
    isLoading: boolean;
}

interface IState {
    asset: IAsset;
    loadAssetBalance: (balancesOverwrite?: WalletAssetBalance[]) => Promise<void>;
    setAssetIsLoading: (isLoading: boolean) => void;
    setSelectedAsset: (selected: CryptoCurrency) => boolean;
    setAssetBalance: (updateFunc: (balance: Record<string, BigNumber>) => Record<string, BigNumber>) => void;
    showDepositModal: boolean;
    setShowDepositModal: (showDepositModal: boolean) => void;
    isAdult: boolean | null;
    setIsAdult: (isAdult: boolean | null) => void;
}

const useAppStore = create<IState>(devtools((set, get) => ({
    asset: {
        selected: CryptoCurrency.BSV,
        balance: { BSV: new BigNumber(0), PGP: new BigNumber(0) },
        isLoading: true,
    },
    loadAssetBalance: async (balancesOverwrite?: WalletAssetBalance[]) => {
        const { data } = balancesOverwrite ? {} as IWalletBalanceResponse : await axiosInstance.get<IWalletBalanceResponse>("/v1/wallets/balance");
        const balances = balancesOverwrite ?? data;

        const balance = balances.reduce(
            (accumulator: Record<string, BigNumber>, { asset, amount }) => ({
                ...accumulator,
                [asset]: new BigNumber(amount),
            }),
            {},
        );

        set(state => ({
            asset: {
                ...state.asset,
                selected: state.asset.selected in balance ? state.asset.selected : balances[0].asset,
                isLoading: false,
                balance,
            },
        }))
    },
    setAssetIsLoading: isLoading => set(state => ({ asset: { ...state.asset, isLoading } })),
    setSelectedAsset: selected => {
        if (!isSet(get().asset.balance[selected])) {
            return false;
        }

        set(state => ({ asset: { ...state.asset, selected } }))
        return true;
    },
    setAssetBalance: updateFunc => set(({ asset }) => ({
        asset: {
            ...asset,
            balance: { ...asset.balance, ...updateFunc(asset.balance) },
        },
    })),

    showDepositModal: false,
    setShowDepositModal: showDepositModal => set({ showDepositModal }),

    isAdult: null,
    setIsAdult: isAdult => set({ isAdult }),
}), { name: "App Store" }));

export default useAppStore;
