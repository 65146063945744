import { Primitive } from "@ntropy/utils";
import { usePrevious } from "@ntropy/hooks/src/changes/usePrevious";
import isEmpty from "lodash/isEmpty";

export function objectFilled<T extends object | any[] | Exclude<Primitive, null | undefined>>(value: T | null | undefined, prevValue: T | null | undefined) {
    return isEmpty(prevValue) && !isEmpty(value);
}

export function useAppeared<T extends object | any[] | Exclude<Primitive, null | undefined>>(
    value: T | null | undefined,
    check = (value: T | null | undefined, prevValue: T | null | undefined) => !prevValue && !!value,
): value is T {
    const prevValue = usePrevious(value);

    return check(value, prevValue);
}

export function useDisappeared<T extends object | any[] | Exclude<Primitive, null | undefined>>(
    value: T | null | undefined,
    check = (value: T | null | undefined, prevValue: T | null | undefined) => !!prevValue && !value,
): boolean {
    const prevValue = usePrevious(value);

    return check(value, prevValue);
}