import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "src/core/http/axios-instance";

interface IProfileImagesResponse {
    timestamp: number;
    data: {
        id: number;
        image_url: string;
    }[]
}

export function useAvatars({ enabled = true }) {
    const { data: avatars } = useQuery({
        enabled,
        queryKey: ["avatars"],
        queryFn: async () => {
            const { data } = await axiosInstance.get<IProfileImagesResponse>("/v1/common/profileimages");

            return (data || []).map(a => ({ id: a.id, imageUrl: a.image_url }));
        },
    });

    return avatars ?? [];
}