import { useEffect, useRef, useState } from "react";
import classNames from "classnames/bind";
import { useMobileOnly } from "helpers/responsive";
import BottomDrawer from "src/components/bottom-drawer/BottomDrawer";
import Modal from "src/components/modal/Modal";
import useClickOutside from "helpers/useClickOutside";
import styles from "./assets/styles.scss";
import useTableDeposit from "./useTableDeposit";
import Deposit from "./Deposit";
import { useGameStore } from "src/components/game/hooks/useGameStore";
import Info from "./Info";

import IconInfo from "../../../../games/assets/images/icon-info-round.svg?inline";
import IconArrowBack from "./assets/arrow-back.svg?inline";
import IconPlus from "../../../../assets/img/plus-square.svg?inline";
import useAppStore from "useAppStore";
import { useAppSocket } from "src/core/application/hooks/useAppSocket";
import CurrencyIcon from "src/components/currency-icon/CurrencyIcon";
import { CryptoCurrency } from "src/core/currency/currency.model";

const cx = classNames.bind(styles);

export default function GameBalance() {
    const isMobileOnly = useMobileOnly();
    const drawerRef = useRef(null);
    const buttonRef = useRef(null);
    const [isInfo, setIsInfo] = useState(false);
    const { depositBalance } = useTableDeposit();
    const bettingDisabled = useGameStore(state => state.bettingDisabled);

    const isOpen = useAppStore(state => state.showDepositModal);
    const setIsOpen = useAppStore.getState().setShowDepositModal;

    useEffect(() => {
        if (!isOpen) {
            setIsInfo(false);
        }
    }, [isOpen]);

    useClickOutside(() => {
        setIsOpen(false);
    }, [drawerRef, buttonRef]);

    const socket = useAppSocket();

    useEffect(() => {
        const handler = () => {
            setIsOpen(true);
        };

        socket.on("LOW_BALANCE", handler);

        return () => {
            socket.off("LOW_BALANCE", handler);
        }
    }, []);

    const header = isInfo ? (
        <div className={cx("info-header", "header")}>
            <button
                type="button"
                aria-label="Back"
                onClick={e => {
                    setIsInfo(false);
                    e.stopPropagation();
                }}
            >
                <IconArrowBack />
            </button>
            <h4>What is Game Balance?</h4>
        </div>
    ) : (
        <div className={cx("deposit-header", "header")}>
            <h4>Game Balance Deposit</h4>
            <button
                type="button"
                aria-label="Close"
                onClick={e => {
                    setIsInfo(true);
                    e.stopPropagation();
                }}
            >
                <IconInfo />
            </button>
        </div>
    );

    const content = isInfo ? <Info goBack={() => setIsInfo(false)} /> : <Deposit closeModal={() => setIsOpen(false)} />;

    return (
        <>
            <button
                type="button"
                className={cx("game-balance", "bold", "flex items-center justify-end text-brand-primary-200 py-2 px-4 rounded-sm hover:bg-support-purple-200")}
                onClick={() => setIsOpen(!isOpen)}
                ref={buttonRef}
            >
                <span className={cx("bold")}>{depositBalance?.toNumber() || "0.0000"}</span>
                <CurrencyIcon currency={CryptoCurrency.BSV} className="w-4 ml-auto mr-2" />
                BSV
                <IconPlus className="w-4 h-4 ml-4" />
            </button>
            {isMobileOnly ? (
                <BottomDrawer isOpen={isOpen} className={cx("mobile-drawer")} ref={drawerRef} unmountOnClose={false}>
                    {header}
                    {content}
                </BottomDrawer>
            ) : (
                <Modal
                    isOpen={isOpen}
                    onClose={() => !bettingDisabled && setIsOpen(false)}
                    header={header}
                    modalClassName={cx("modal")}
                    unmountOnClose={false}
                >
                    {content}
                </Modal>
            )}
        </>
    );
}
