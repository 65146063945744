export function getCardsScores(cards) {
    let hasAce = false;

    const totalValue = cards.reduce((accumulator, card) => {
        if (card[0] === "A") {
            hasAce = true;
        }
        let value = card[0].replace("A", "1");
        value = ["Q", "K", "J", "T"].includes(value) ? 10 : Number(value);
        return accumulator + value;
    }, 0);

    if (hasAce) {
        if (totalValue + 10 < 21) {
            return [totalValue, totalValue + 10];
        }
        if (totalValue + 10 === 21) {
            return [totalValue + 10];
        }
    }
    return [totalValue];
}
