import { useState } from "react";
import classNames from "classnames/bind";
import Detail from "src/components/detail/Detail";
import styles from "../assets/styles.scss";
import RandomNumber from "src/containers/FairnessCalculation/RandomNumber/RandomNumber";
import useShuffledCards from "../hooks/useShuffledCards";
import ResultTable from "../ResultTable/ResultTable";
import useRandomNumber from "../hooks/useRandomNumber";
import { GameCode } from "src/core/casino/casino.model";

interface IBaccaratProps {
    data: {
        game_id: number;
        game_code: GameCode;
        seed_hash: string;
        next_game_id: number;
        next_seed_hash: string;
        prev_txids: string[]
    }
}

const cx = classNames.bind(styles);

export default function Baccarat({ data }: IBaccaratProps) {
    const {
        game_id: gameId,
        game_code: gameCode,
        seed_hash: seedHash,
        next_game_id: nextGameId,
        next_seed_hash: nextSeedHash,
    } = data;

    const [txIds, setTxIds] = useState(data.prev_txids || []);

    function onTxIdChange(index: number, value: string) {
        const updatedTxIds = [...txIds];
        updatedTxIds[index] = value;
        setTxIds(updatedTxIds);
    }

    const randomNumber = useRandomNumber(seedHash, txIds);
    const shuffledCards = useShuffledCards(randomNumber);

    return (
        <div>
            <Detail
                contentClassName={cx("small")}
                className={cx("detail")}
                label={`Next Round Seed (${nextGameId || "-"})`}
                content={nextSeedHash}
                copiable={!!nextSeedHash}
            />
            <Detail
                contentClassName={cx("small")}
                className={cx("detail")}
                label={`Current Round Seed (${gameId || "-"})`}
                content={seedHash}
                copiable={!!seedHash}
            />
            <RandomNumber txIds={txIds} onTxIdChange={onTxIdChange} seedHash={seedHash} isCardOrder />
            <ResultTable game={gameCode} result={shuffledCards} />
        </div>
    );
}

