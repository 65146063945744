import classNames from "classnames/bind";
import styles from "./styles.scss";

const cx = classNames.bind(styles);

export default function Loader() {
    return (
        <div className={cx("spinner")}>
            <div className={cx("double-bounce1")}/>
            <div className={cx("double-bounce2")}/>
        </div>
    );
}
