import { ReactElement } from "react";
import IconSlots from "../../../assets/img/slots.svg?inline";
import IconTableGames from "../../../assets/img/table-games.svg?inline";
import IconGames from "../../../assets/img/games.svg?inline";
import IconInhouse from "../assets/inhouse.svg?inline";
import IconCasual from "../assets/casual.svg?inline";
import { CasinoGameCategory } from "src/core/casino/casino.model";

interface ICategories {
    name: string;
    getTitle: (isMobileOnly?: boolean) => string;
    icon: ReactElement;
}

export const categories: ICategories[] = [
    { name: "", getTitle: (isMobileOnly: boolean) => (isMobileOnly ? "All" : "All Games"), icon: <IconGames /> },
    { name: CasinoGameCategory.InHouse, getTitle: () => "In-House", icon: <IconInhouse /> },
    { name: CasinoGameCategory.Slots, getTitle: () => "Slots", icon: <IconSlots /> },
    { name: CasinoGameCategory.Table, getTitle: () => "Table", icon: <IconTableGames /> },
    { name: CasinoGameCategory.Casual, getTitle: () => "Casual", icon: <IconCasual /> },
];