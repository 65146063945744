import { DialogHeader, DialogTitle } from "src/components/dialog/Dialog";
import { CurrencyAndChainSelect } from "src/feature/wallet/components/currency-and-chain-select/CurrencyAndChainSelect";
import { useState } from "react";
import { Input } from "src/components/shadcn-input/Input";
import { useWithdraw, IWithdrawRequest } from "src/feature/wallet/hooks/useWithdraw";
import { Chain, CryptoCurrency } from "src/core/currency/currency.model";
import { useGetWalletAccounts } from "src/core/wallet/hooks/useWalletAccounts/useGetWalletAccounts";
import NumericInput from "src/components/shadcn-input/NumericInput";
import { Button } from "src/components/shadcn-button/Button";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { isSet } from "@ntropy/utils/src/type-utils";
import { getCryptoCurrencyName } from "src/core/currency/currency.function";
import WAValidator from "multicoin-address-validator";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, FormField, FormItem, FormControl, FormMessage } from "src/components/form/Form";
import { useSelectedOrFormCurrency } from "src/feature/wallet/hooks/useSelectedOrFormCurrency";

import CurrencyIcon from "src/components/currency-icon/CurrencyIcon";
import { CRYPTO_DECIMAL } from "src/core/currency/currency.const";

interface IWithdrawFormValues {
    amount: number | null
    address: string
}

interface IWithdrawFormContext {
    currency: CryptoCurrency
    chain: Chain | null
    balance: number | null
}

const schema = Yup.object<IWithdrawFormContext>().shape({
    amount: Yup.number<number, IWithdrawFormContext>()
        .required("Amount to withdraw is required")
        .min(0, "Cannot withdraw amount below 0")
        .when("$balance", ([balance]: [number | null], schema) => {
            if (!isSet(balance)) {
                return schema;
            }

            return schema.max(balance, "Cannot withdraw more than your balance");
        }),
    address: Yup.string<string, IWithdrawFormContext>()
        .required("Withdrawal address is required")
        .when("$currency", ([currency]: [CryptoCurrency], schema) => {
            return schema.test("address valid", `Your ${getCryptoCurrencyName(currency)} wallet address is invalid`, address => {
                const currencyLower = currency.toLowerCase();

                if (!WAValidator.findCurrency(currencyLower)) {
                    console.error(`Currency ${currency} is not supported by WAValidator`);
                    return true;
                }

                return WAValidator.validate(address, currencyLower)
            })
        }),
});

export const WithdrawalView = () => {
    const [currency, setCurrency] = useSelectedOrFormCurrency();
    const [chain, setChain] = useState<Chain | null>(null);

    const { data } = useGetWalletAccounts({ select: accounts => accounts.find(a => a.currencyTicker === currency) });

    const methods = useForm<IWithdrawFormValues, IWithdrawFormContext>({
        mode: "onTouched",
        resolver: yupResolver<IWithdrawFormValues>(schema),
        context: { currency, chain, balance: data?.balance ?? null },
        defaultValues: {
            amount: null,
            address: "",
        },
    })

    const withdraw = useWithdraw(currency)

    const handleWithdraw = async (values: IWithdrawFormValues) => {
        const withdrawPayload: IWithdrawRequest = {
            amount: values.amount,
            chain,
            address: values.address,
        }

        await withdraw(withdrawPayload);
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleWithdraw)}>
                <DialogHeader>
                    <DialogTitle className="text-left">
                        Withdraw Money
                    </DialogTitle>
                </DialogHeader>

                <section className="flex flex-col gap-4 mt-4">
                    <CurrencyAndChainSelect
                        currency={currency}
                        onCurrencyChange={setCurrency}
                        onChainChange={setChain}
                        hideZeros
                    />

                    <FormField
                        control={methods.control}
                        name="amount"
                        render={({ field }) => (
                            <FormItem>
                                <h3 className="text-sm mb-2 select-none">Amount to withdraw</h3>
                                <FormControl>
                                    <NumericInput
                                        {...field}
                                        suffix={<CurrencyIcon currency={currency} />}
                                        decimal={CRYPTO_DECIMAL}
                                        min={0}
                                        max={data?.balance ?? Infinity}
                                        className="bg-brand-primary-200"
                                        withMaxButton
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={methods.control}
                        name="address"
                        render={({ field }) => (
                            <FormItem>
                                <h3 className="text-sm mb-2 select-none">Withdrawal address</h3>
                                <FormControl>
                                    <Input
                                        {...field}
                                        className="bg-brand-primary-200"
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <Button type="submit" className="w-full text-md text-white">Withdraw</Button>
                </section>
            </form>
        </FormProvider>
    )
}