import { useEffect } from "react";
import { useLocation } from "react-router-dom";

type THandler = () => void;

export default function useLocationChange(handler: THandler): void {
    const { pathname } = useLocation();

    useEffect(() => {
        handler();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
}
