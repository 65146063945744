import styled, { css } from "styled-components";
import { FlexContainer } from "src/affiliate/styled/layout.styled";
import { forwardRef, ComponentPropsWithoutRef } from "react";
import { GlobalError } from "react-hook-form";

interface InputProps extends ComponentPropsWithoutRef<"input"> {
    label: string;
    name: string;
    inputType: string;
    error?: Record<string, GlobalError>;
    backGroundColor?: string;
    value?: string;
    readOnly?: boolean;
    disabled?: boolean;
    border?: string;
    modalInputActive?: boolean;
    opacity?: number;
    serverError?: string;
    accountSettings?: boolean;
}

interface IStyledInputProps {
    $border?: string;
    $backGroundColor?: string;
    $opacity?: number;
    $modalInputActive?: boolean;

}

const StyledLabel = styled.label`
    opacity: 0.6;
    color: #f8f5ff;
    padding-bottom: 4px;
`;
//color: ${({ theme, opacity }) => (opacity ? "rgba(255,255,255, 0.5)" : theme.color.white)};
const StyledInput = styled.input<IStyledInputProps>`
    width: 100%;
    height: 50px;
    border-radius: 4px;
    padding: 14px 0 14px 16px;
    border: ${({ $border }) => $border || "solid 1px rgba(199, 186, 241, 0.2)"};
    background-color: ${({ $backGroundColor }) => $backGroundColor || "#0d0428"};
    color: ${({ theme, $opacity }) => ($opacity ? "rgba(255,255,255, 0.5)" : theme.color.white)};

    &:focus {
        ${({ $modalInputActive }) => $modalInputActive
            ? css`
                border: 1px solid #c7baf1;
                background-color: #0d0428;
            `
            : css`
                border: 1px solid #c7baf1;
                background-color: #12033d;
                outline: #c7baf1;
            `}
    }

    transition: all 0.3s ease;
`;

const StyledSpan = styled.span`
    margin: 0;
    color: ${({ theme }) => theme.color.error};
    padding-top: 2px;
`;

const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            label,
            error,
            inputType,
            name,
            value,
            backGroundColor,
            readOnly,
            disabled,
            border,
            modalInputActive,
            opacity,
            serverError,
            accountSettings, ...rest
        },
        ref,
    ) => (
        <>
            {accountSettings ? (
                <FlexContainer flexDirection="column" width="100%">
                    <StyledLabel className="small">{label}</StyledLabel>
                    <StyledInput
                        ref={ref}
                        {...rest}
                        className="small"
                        readOnly={readOnly}
                        $backGroundColor={backGroundColor}
                        type={inputType}
                        name={name}
                        defaultValue={value}
                        disabled={disabled}
                        $border={border}
                        $modalInputActive={modalInputActive}
                        $opacity={opacity}
                    />
                </FlexContainer>
            ) : (
                <FlexContainer flexDirection="column" width="100%">
                    <StyledLabel className="small">{label}</StyledLabel>
                    <StyledInput
                        ref={ref}
                        {...rest}
                        className="small"
                        readOnly={readOnly}
                        $backGroundColor={backGroundColor}
                        type={inputType}
                        name={name}
                        defaultValue={value}
                        disabled={disabled}
                        $border={error?.[name] || serverError ? "solid 1px #ff2976" : "solid 1px rgba(199, 186, 241, 0.2)"}
                        $modalInputActive={modalInputActive}
                        $opacity={opacity}
                    />
                    {error?.[name] || serverError ? (
                        <StyledSpan className="xsmall">{(error?.[name] && error[name].message) || serverError}</StyledSpan>
                    ) : null}
                </FlexContainer>
            )}
        </>
    ),
);

Input.displayName = "Input"

export default Input;
