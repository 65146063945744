import Helmet from "react-helmet";
import { Redirect, useHistory } from "react-router-dom";
import DropDown from "src/affiliate/components/reusable/drop-down/DropDown";
import useDropdown from "src/affiliate/hooks/use-dropdown";
import { Caption, H4 } from "src/affiliate/styled/typography.styled";
import DashboardBlog from "src/affiliate/components/dash-board/DashboardBlog";
import useCampaign from "src/affiliate/hooks/use-campaign";
import Loader from "src/components/loader/Loader";
import Layout from "src/affiliate/components/Layout";
import useFetch from "src/helpers/useFetch";
import DashBoardLayout from "src/affiliate/components/reusable/DashBoardLayout";
import { FlexContainer, FlexItem } from "src/affiliate/styled/layout.styled";
import DataCard from "src/affiliate/components/reusable/DataCard";
import Button from "src/affiliate/components/reusable/Button";
import { useAppSelector } from "src/redux/reducer";
import { useAuthorizedState } from "src/core/auth/hooks/useAuthorizedState";

interface IDashboardInfoResponse {
    visits: number;
    referrals: number;
    wagered: number;
    commissions: number;
}

interface IDashboardCampaignResponse {
    name: string;
    created_at: string;
    link: string;
}

const listItems = [
    { name: "All Campaigns", value: "ALL" },
    { name: "Last 24 Hours", value: "24h" },
    { name: "Last 7 Days", value: "7d" },
    { name: "Last 30 Days", value: "30d" },
];

const Dashboard = () => {
    const { push } = useHistory()

    const {
        value, onClickHandler, trigger, setTrigger,
    } = useDropdown({ name: "All Campaigns", value: "ALL" });

    const { isAuthorized } = useAuthorizedState();

    const affiliateStatus = useAppSelector(({ affiliate: { status } }) => status);
    const isLoading = useAppSelector(({ affiliate }) => !isAuthorized || (isAuthorized && !affiliate.loaded));

    const { data: info, loaded: infoLoaded } = useFetch<IDashboardInfoResponse>(`/api/v1/affiliate/dashboard/info?period=${value.value}`);
    const {
        data: campaign,
        loaded: campaignLoaded,
    } = useFetch<IDashboardCampaignResponse>("/api/v1/affiliate/dashboard/campaign");
    const { campaignList, totalPages, loadedCampaigns } = useCampaign("/api/v1/affiliate/campaign?size=3&page");

    let mainComponent = <Loader />;

    if (isLoading) {
        mainComponent = <Loader />;
    } else if (affiliateStatus !== "ACTIVE") {
        mainComponent = <Redirect to="registration" />;
    } else if (infoLoaded) {
        mainComponent = (
            <DashBoardLayout
                title="Dashboard"
                component={(
                    <FlexContainer mobileWidth="100%" mobileJustifyContent="flex-end">
                        <FlexItem width="180px">
                            <DropDown
                                value={value}
                                onClickHandler={onClickHandler}
                                listItems={listItems}
                                trigger={trigger}
                                setTrigger={setTrigger}
                            />
                        </FlexItem>
                    </FlexContainer>
                )}
            >
                <FlexContainer justifyContent="space-between" margin="24px 0 60px 0" mobileFlexWrap>
                    <FlexItem width="170px" mobileWidth="49%" tabletWidth="21%" mobileMargin="0 0 8px 0">
                        <DataCard name="Visits" value={info?.visits || 0} />
                    </FlexItem>
                    <FlexItem width="170px" mobileWidth="49%" tabletWidth="21%" mobileMargin="0 0 8px 0">
                        <DataCard name="Referrals" value={info?.referrals || 0} />
                    </FlexItem>
                    <FlexItem width="206px" mobileWidth="100%" tabletWidth="28%" mobileMargin="0 0 8px 0">
                        <DataCard name="Wagered" value={info?.wagered || 0} bsIcon />
                    </FlexItem>
                    <FlexItem width="206px" mobileWidth="100%" tabletWidth="28%" mobileMargin="0 0 8px 0">
                        <DataCard name="Commission" value={info?.commissions || 0} bsIcon />
                    </FlexItem>
                </FlexContainer>

                <H4>Referral Link</H4>
                <Caption color="#f8f5ff" margin="16px 0 32px 0">
                    Being a Peergame Partner means you will earn commission for all bets placed on our Casino - <br />
                    which unlike traditional affiliate programs, means that whether they win or lose, you still make the same
                    commission!
                </Caption>
                <DashboardBlog name={campaign?.name || ""} createdAt={campaign?.created_at || ""} link={campaign?.link || ""} />
                {loadedCampaigns && totalPages === 1 && campaignList.length === 1 ? null : (
                    <FlexItem width="260px" margin="40px 0 0 0" mobileMargin="40px 0 0 0">
                        <Button buttonShape="bordered" onClick={() => push("campaigns")}>
                            All Campaigns
                        </Button>
                    </FlexItem>
                )}
            </DashBoardLayout>
        )
    }

    return (
        <>
            <Helmet>
                <title>Peergame Partners - Dashboard</title>
                <meta property="og:title" content="Peergame Partners - Dashboard" />
                <meta
                    name="description"
                    content="The original Bitcoin SV Dice Game. Provably fair with the lowest house edge, instant payout, and enhanced privacy"
                />
            </Helmet>
            <Layout transparent sideNav>
                {mainComponent}
            </Layout>
        </>
    );
};

export default Dashboard;
