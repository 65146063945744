import classNames from "classnames/bind";
import { useQueryParam, withDefault } from "use-query-params";
import styles from "./assets/styles.scss";
import Dropdown from "src/components/dropdown/Dropdown";
import HistoryTable from "src/containers/Account/History/HistoryTable";
import { games } from "src/games/config";
import { InHouseGame, GameCodeParam, GameCode } from "src/core/casino/casino.model";
import { GameHistoryStatus, GameHistoryStatusParam } from "src/containers/Account/hooks/useGameHistory/game-history.model";
import { useCurrentUsedApi } from "src/core/application/hooks/useCurrentUsedApi";

const cx = classNames.bind(styles);

const betStatusOptions: { label: string, value: "" | GameHistoryStatus }[] = [
    { label: "All Transactions", value: "" },
    { label: "Confirmed Bets", value: GameHistoryStatus.Completed },
    { label: "Pending Bets", value: GameHistoryStatus.Pending },
    { label: "Failed Bets", value: GameHistoryStatus.Fail },
];

const gameOptions = Object.entries(games).map(([gameCode, { label }]) => ({ label, value: gameCode as GameCode }));

const History = () => {
    const {shouldUseHandcash} = useCurrentUsedApi();
    const [betStatus, setBetStatus] = useQueryParam("betStatus", withDefault(GameHistoryStatusParam, betStatusOptions[0].value));
    const [gameCode, setGameCode] = useQueryParam("gameCode", withDefault(GameCodeParam, InHouseGame.Dice));

    return (
        <>
            <div className={cx("header")}>
                <h3>Game History</h3>
                {shouldUseHandcash &&
                    <div className={cx("filters")}>
                        <Dropdown
                            items={betStatusOptions}
                            selectedValue={betStatus}
                            onChange={(newValue: "" | GameHistoryStatus) => setBetStatus(newValue)}
                        />
                        <Dropdown
                            items={gameOptions}
                            selectedValue={gameCode}
                            onChange={(newValue: GameCode) => setGameCode(newValue)}
                        />
                    </div>
                }
            </div>
            <HistoryTable gameCode={gameCode} betStatus={betStatus} />
        </>
    );
};

export default History;
