import EventEmitter from "eventemitter3";
import { CallbackFn } from "@ntropy/utils/src/typescript-helpers";

export interface ICancelablePromise<R = any> {
    promise: Promise<R>
    isCancelled: boolean
    isFinished: boolean
    cancel(): void
}

export const delayFor = (timeInMs = 0) => {
    return new Promise<void>((resolve) => {
        setTimeout(resolve, timeInMs);
    })
}

export const promiseNoop = <T = void>(...args: any[]): Promise<T> => new Promise<T>(r => r(void 0 as T));

export function promiseDebounce<T extends any[], R>(
    exec: (...args: T) => Promise<R>,
    interval: number,
    leading = false,
): ((...args: T) => Promise<R>) & {cancel(): void} {
    let handle: ReturnType<typeof setTimeout> | undefined;
    let resolves: ((value: R) => void)[] = [];
    let globalResult: R | undefined;

    const debouncedFunction = async (...args: T) => {
        const isFirstLeading = leading && handle === undefined;

        const callback = async () => {
            let result: R;

            if (isFirstLeading) {
                globalResult = await exec(...args);
                result = globalResult;
            } else if (leading) {
                result = globalResult!;
            } else {
                result = await exec(...args);
            }

            resolves.forEach((resolve) => resolve(result));
            resolves = [];
        };

        clearTimeout(handle!);

        handle = setTimeout(leading ? () => {
            handle = undefined;
        } : callback, interval);

        if (leading) {
            callback()
        }

        return new Promise<R>((resolve) => resolves.push(resolve));
    };

    debouncedFunction.cancel = () => {
        clearTimeout(handle!)
        handle = undefined;
        resolves = [];
        globalResult = undefined;
    }

    return debouncedFunction;
}

export function makeCancelable<R = any>(promise: Promise<R>, resolveOnCancel?: false): ICancelablePromise<R>;
export function makeCancelable<R = any>(promise: Promise<R>, resolveOnCancel: true): ICancelablePromise<R | { isCanceled: true }>;
export function makeCancelable<R = any>(promise: Promise<R>, resolveOnCancel?: boolean): ICancelablePromise<R | { isCanceled: true }> {
    const eventEmitter = new EventEmitter();
    let reactedOnPromise = false;
    const cancelablePromise = {
        isCancelled: false,
        isFinished: false,
    } as ICancelablePromise<R | { isCanceled: true }>;

    cancelablePromise.promise = new Promise<R | { isCanceled: true }>((resolve, reject) => {
        eventEmitter.once("cancelled", () => {
            if (!reactedOnPromise) {
                cancelablePromise.isCancelled = true;
                cancelablePromise.isFinished = true;
                if (resolveOnCancel) {
                    resolve({ isCanceled: true })
                } else {
                    reject({ isCanceled: true })
                }
            }
        })
        promise
            ?.then(
                val => {
                    reactedOnPromise = true;
                    cancelablePromise.isFinished = true;
                    if (!cancelablePromise.isCancelled) resolve(val)
                },
                error => {
                    reactedOnPromise = true;
                    cancelablePromise.isFinished = true;
                    if (!cancelablePromise.isCancelled) reject(error)
                },
            )
    });

    cancelablePromise.cancel = () => {
        eventEmitter.emit("cancelled")
        cancelablePromise.promise.catch(e => {
            if (e.isCanceled) {
                return;
            }

            throw e;
        })
    };

    return cancelablePromise;
}

export class PromiseList {
    list: ICancelablePromise<any>[] = [];

    addPromise = <R = any>(...promises: Promise<R>[]): ICancelablePromise<R>[] => {
        const cancelablePromises = promises.map(promise => makeCancelable<R>(promise));

        this.list = [
            ...this.list,
            ...cancelablePromises,
        ];

        return cancelablePromises;
    };

    cancelAllPromises = () => {
        this.list.forEach(cancelablePromise => cancelablePromise.cancel());
        this.list = [];
    };
}

export class PromiseQueue<T> {
    queue = Promise.resolve();

    add(callback: CallbackFn<[], Promise<T>>) {
        return new Promise<T>((resolve, reject) => {
            this.queue = this.queue
                .then(callback)
                .then(resolve)
                .then(() => {
                    this.queue = Promise.resolve()
                })
                .catch(reject)
        });
    }
}