import { CarouselItem } from "src/components/carousel/Carousel";
import GameThumbnail from "src/components/game-thumbnail/GameThumbnail";

interface GameCardCarouselItemProps {
    title: string;
    url: string;
    thumbnailSrc: string;
    provider: string;
    isDisabled: boolean;
    className?: string;
}

export const GameCardCarouselItem = ({ url, provider, isDisabled, title, thumbnailSrc }: GameCardCarouselItemProps) => {
    return (
        <CarouselItem className="basis-[66%] md:basis-1/4 lg:basis-1/6">
            <GameThumbnail
                title={title} url={url} thumbnailSrc={thumbnailSrc} provider={provider} isDisabled={isDisabled}
                className="h-[213px] w-full"
            />
        </CarouselItem>
    )
}