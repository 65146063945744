import { ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import THEME from "src/affiliate/constants/themes.constants";
import CloseIcon from "src/components/base-modal/CloseIcon";
import { StyledModal, StyledSvgContainer, StyledModalTitle, StyledOverlay } from "src/components/base-modal/base-modal.styled";

export interface IBaseModalProps {
    children: ReactNode,
    open: boolean,
    onClick: () => void,
    title: string,
}

const BaseModal = ({
    children, open, onClick, title,
}: IBaseModalProps) => (
    <>
        <ThemeProvider theme={THEME}>
            <StyledModal open={open}>
                <StyledSvgContainer onClick={() => onClick()}>
                    <CloseIcon />
                </StyledSvgContainer>
                <StyledModalTitle>{title}</StyledModalTitle>
                {children}
            </StyledModal>
            <StyledOverlay open={open} onClick={() => onClick()} />
        </ThemeProvider>
    </>
);

export default BaseModal;
