import { useMutation } from "@tanstack/react-query";
import { axiosInstance } from "src/core/http/axios-instance";
import { Endpoint } from "src/core/http/endpoint";
import { queryClient } from "src/core/query/query-client";
import { WalletProvider } from "src/core/wallet/wallet.model";
import { successToast } from "src/helpers/toast";
import { useWalletProvider } from "src/core/wallet/hooks/useWalletProvider";

export function useChangeNickname({onSuccess}: { onSuccess?(): void }) {
    const [walletProvider] = useWalletProvider();

    const {mutateAsync, isPending} = useMutation({
        mutationFn: async (nickname: string) => {
            switch (walletProvider) {
                case WalletProvider.WalletConnect:
                    await axiosInstance.patch(Endpoint.UserNicknameChange, nickname);
                    break;
                case WalletProvider.HandCash:
                    await axiosInstance.post("/v1/mypage/modify/nickname", { nickname });
                    break;

            }
        },
        onSuccess: () => {
            onSuccess?.();
            successToast("Username updated successfully");
            queryClient.invalidateQueries({ queryKey: ["user-info"], exact: false });
        },
    })

    return [mutateAsync, isPending] as const;
}