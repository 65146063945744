import { Carousel, CarouselContent, CarouselApi } from "src/components/carousel/Carousel";
import { useState } from "react";
import { ChevronRight, ChevronLeft } from "lucide-react";
import { Button } from "src/components/shadcn-button/Button";

interface IBasicCarouselProps {
    children?: React.ReactNode;
}

export const BasicCarousel = ({ children }: IBasicCarouselProps) => {
    const [api, setApi] = useState<CarouselApi>()

    const arrowButtonStyles = "flex items-center justify-center p-2"

    return (
        <>
            <div className="flex gap-1 items-center mb-4 text-white">
                <Button onClick={() => api?.scrollPrev()} variant="ghost" className={arrowButtonStyles}>
                    <ChevronLeft className="w-5 h-5" />
                </Button>

                <Button onClick={() => api?.scrollNext()} variant="ghost" className={arrowButtonStyles}>
                    <ChevronRight className="w-5 h-5" />
                </Button>
            </div>

            <Carousel className="w-full" setApi={setApi} opts={{ loop: true, align: "start", slidesToScroll: "auto" }}>
                <CarouselContent>
                    {children}
                </CarouselContent>
            </Carousel>
        </>

    )
}