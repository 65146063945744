import io from "socket.io-client";
import config from "src/config";
import { CallbackFn } from "@ntropy/utils/src/typescript-helpers";

class SocketService {
    socket = io(config.chatEndpoint!, {
        transports: ["websocket", "polling"],
        reconnection: true,
        reconnectionDelay: 3000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,
    });

    on = <TData>(event: string, callback: CallbackFn<[TData]>) => {
        return this.socket.on(event, callback);
    }

    once = <TData>(event: string, callback: CallbackFn<[TData]>) => {
        return this.socket.once(event, callback);
    }

    off = <TData>(event: string, callback: CallbackFn<[TData]>) => {
        return this.socket.off(event, callback);
    }

    emit = <TData>(event: string, callback: CallbackFn<[TData]>) => {
        return this.socket.emit(event, callback);
    }

    hasListeners = (event: string) => {
        return this.socket.hasListeners(event);
    }
}

const socketService = new SocketService();

socketService.socket.connect();

export default socketService;