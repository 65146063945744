import { useState } from "react";
import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import { games } from "src/games/config";
import BetDetails from "src/containers/Account/History/BetDetails";
import { GameCode, isExternalGameCategory } from "src/core/casino/casino.model";
import { GameHistoryEntry } from "src/containers/Account/hooks/useGameHistory/game-history.model";
import CurrencyAmount from "src/components/currency-amount/CurrencyAmount";

const cx = classNames.bind(styles);

interface IHistoryTableRowProps {
    gameCode: GameCode;
    entry: GameHistoryEntry
}

const formatPick = (gameCode: GameCode | null | undefined, area: string | number | object | null | undefined): string => {
    if (!gameCode || !area || !games[gameCode]) return "";

    return games[gameCode].formatPick?.(area) || "";
}

const formatResult = (gameCode: GameCode | null | undefined, result: string | null | undefined): string => {
    if (!gameCode || !result || !games[gameCode]) return "";

    return games[gameCode].formatResult?.(result) || "";
}

const HistoryTableRow = ({
    gameCode,
    entry: { gameName, roundId, result, area, status, multiplier, betAmount, currency, isWin, payout, transaction },
}: IHistoryTableRowProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const formattedPayoutRate = `${multiplier?.toFixed(2) || "0.00"}x`;
    const formattedPick = formatPick(gameCode, area);
    const formattedResult = formatResult(gameCode, result);

    return (
        <tr className={cx("small")} onClick={() => setIsOpen(!isOpen)}>
            {/*Round ID*/}
            <td className={cx({ "hide-mobile": isExternalGameCategory(gameCode) })}>{roundId || "-"}</td>
            {/* Game */}
            {isExternalGameCategory(gameCode) && gameName && <td>{gameName}</td>}
            {/* Bet */}
            <td className={cx("bet-amount")}>
                <CurrencyAmount amount={betAmount} currency={currency} />
            </td>
            {/* Multiplayer */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-expect-error */}
            {gameCode !== "bitto" && (
                <td className={cx({ "hide-mobile": isExternalGameCategory(gameCode) })}>{formattedPayoutRate}</td>
            )}
            {/* My Pick */}
            {!isExternalGameCategory(gameCode) && <td className={cx("hide-mobile")}>{formattedPick}</td>}
            {/* Result */}
            {!isExternalGameCategory(gameCode) && <td className={cx("hide-mobile")}>{formattedResult || "-"}</td>}
            {/* Payout, Win/Loss */}
            <td>
                <BetDetails
                    isOpen={isExternalGameCategory(gameCode) ? false : isOpen}
                    onClose={() => setIsOpen(false)}
                    gameCode={gameCode}
                    isWin={isWin}
                    status={status}
                    betAmount={betAmount}
                    payout={payout}
                    payoutRate={formattedPayoutRate}
                    playerPick={formattedPick}
                    result={formattedResult}
                    currency={currency}
                    transaction={transaction}
                />
            </td>
        </tr>
    );
};

export default HistoryTableRow;
