import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "src/core/http/axios-instance";
import { Endpoint } from "src/core/http/endpoint";
import { HandcashCurrency, CryptoCurrency, FiatCurrency } from "src/core/currency/currency.model";
import { useWalletProvider } from "src/core/wallet/hooks/useWalletProvider";
import { isSupportedWalletProvider, WalletProvider } from "src/core/wallet/wallet.model";

interface IRank {
    ranking: number;
    point: number
}

interface IUserStatistics {
    totalWager: number
    currency: HandcashCurrency | FiatCurrency.USD
    daily?: IRank | null;
    weekly?: IRank | null;
}

interface ICashierStatisticsResponse {
    totalWager: number
}

interface IV1PointsResponse {
    timestamp: number;
    data: {
        daily_rank: IRank | null;
        total_wager: number;
        weekly_rank: IRank | null;
    }
}

const mapCashierStatisticsToUserStatistics = (dto: ICashierStatisticsResponse): IUserStatistics => {
    return {
        totalWager: dto.totalWager,
        currency: FiatCurrency.USD,
    }
}

const mapV1PointsToUserStatistics = (dto: IV1PointsResponse): IUserStatistics => {
    return {
        totalWager: dto.data.total_wager,
        currency: CryptoCurrency.BSV,
        daily: dto.data.daily_rank,
        weekly: dto.data.weekly_rank,
    }
}

export function useUserStatistics() {
    const [walletProvider] = useWalletProvider();

    const {data, isLoading} = useQuery({
        queryKey: ["user-statistics", walletProvider],
        enabled: isSupportedWalletProvider(walletProvider),
        queryFn: async () => {
            if (!isSupportedWalletProvider(walletProvider)) {
                return;
            }

            switch (walletProvider) {
                case WalletProvider.WalletConnect: {
                    const data = await axiosInstance.get<ICashierStatisticsResponse>(Endpoint.UserStatistics);

                    return mapCashierStatisticsToUserStatistics(data);
                }
                case WalletProvider.HandCash: {
                    const data = await axiosInstance.get<IV1PointsResponse>(Endpoint.HandcashMyPoints);

                    return mapV1PointsToUserStatistics(data);
                }
            }
        },
    })

    return [data, isLoading] as const;
}