import { FC } from "react";
import { HandcashTransactionDetails, GameHistoryStatus } from "src/containers/Account/hooks/useGameHistory/game-history.model";
import Detail from "src/components/detail/Detail";
import classNames from "classnames/bind";
import styles from "src/containers/Account/History/assets/styles.scss";

const cx = classNames.bind(styles);

interface IHandcashTransactionsDetailsProps {
    transaction: HandcashTransactionDetails
    status: GameHistoryStatus | undefined
}

const HandcashTransactionDetail: FC<{txId: string | null | undefined, txIdForUrl?: string, label: string, content?: string, className?: string}> = ({
    txIdForUrl,
    txId,
    label,
    content,
    className,
}) => {
    const urlTxId = txIdForUrl ?? txId;

    return (
        <Detail
            label={label}
            className={cx("full-width")}
            contentClassName={cx("xsmall", className)}
            content={content || txId || "-"}
            copiable={!content && !!txId}
            href={urlTxId ? `https://whatsonchain.com/tx/${urlTxId}` : undefined}
        />
    )
}

const HandcashTransactionsDetails: FC<IHandcashTransactionsDetailsProps> = ({transaction: { txId, txIdForUrl, payoutTxId }, status}) => {
    let statusText: string | undefined;
    let statusClassName: string | undefined;

    if (status === GameHistoryStatus.Fail) {
        statusClassName = "red";
        statusText = "Failed Bet";
    } else if (status === GameHistoryStatus.Pending) {
        statusClassName = "yellow";
        statusText = "Pending Bet";
    }
    return (
        <>
            <HandcashTransactionDetail
                label="Bet TxID"
                className={statusClassName}
                content={statusText}
                txId={txId}
                txIdForUrl={txIdForUrl}
            />
            {status !== GameHistoryStatus.Fail && <HandcashTransactionDetail label="Payout TxID" txId={payoutTxId} />}
        </>
    );
};

export default HandcashTransactionsDetails;