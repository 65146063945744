import PageContainer from "src/containers/PageContainer/PageContainer";

import { TrendingGames } from "src/containers/Landing/components/trending-games/TrendingGames";
import { CardWithImage } from "src/components/card-with-image/CardWithImage";
import { SocialMediaLinks } from "src/components/social-media-links/SocialMediaLinks";
import topHeadingImage from "src/containers/Landing/assets/images/top-header.png"
import referralHeroImage from "src/containers/Landing/assets/images/referral-hero.png"
import bottomHeadingImage from "src/containers/Landing/assets/images/bottom-hero.png"
import { SimpleCard } from "src/simple-card/SimpleCard";
import { AboutCallToAction } from "src/containers/Landing/components/about-call-to-action/AboutCallToAction";
import { SupportCallToAction } from "src/containers/Landing/components/support-call-to-action/SupportCallToAction";

export default function Landing() {

    return (
        <PageContainer showHeader showFooter dark contentClass="flex flex-col gap-10 px-4 md:px-8 lg:px-[70px] py-10" pageTitle="Peergame">
            <CardWithImage
                title="Experience true freedom with casino gaming"
                imageSrc={topHeadingImage}
                description="Connect your wallet and enjoy secure, no-registration-required casino games today!"
                buttonText="Play Now"
                anchorHref="/games"
                cardFooterContent={<SocialMediaLinks />}
                imageClassname="md:mb-[-50px]"
            />

            <TrendingGames />

            <CardWithImage
                title="Refer a Friend"
                imageSrc={referralHeroImage}
                description="Invite your friends to PeerGame and earn commissions every time they bet on our blockchain casino games. The more they play, the more you earn"
                buttonText="Get refferal link"
                anchorHref="/partners"
                variant="secondary"
                imageClassname="mb-[-40px] md:mb-0"
                gradientDirection="to-right"
                imagePlacement="bottom"
            />

            <section className="flex flex-col lg:flex-row gap-6">
                <SimpleCard
                    title="About"
                    description="PeerGame is a leading blockchain casino that prioritizes transparency, security, and privacy, offering a seamless gaming experience with direct wallet connections and instant payouts. Whether you're new to blockchain gaming or a seasoned player, PeerGame provides a world-class selection of casino games designed for all."
                    callToAction={<AboutCallToAction />}
                />
                <SimpleCard
                    title="Support"
                    description="Need assistance? Explore our Help Center for FAQs, detailed guides and contact options to get help with PeerGame’s casino games."
                    callToAction={<SupportCallToAction />}
                />
            </section>

            <CardWithImage
                title="Join the world of blockchain casino gaming"
                imageSrc={bottomHeadingImage}
                description="Connect your wallet now and enjoy instant access to our top games."
                buttonText="Play Now"
                anchorHref="/games"
                variant="secondary"
                imageClassname=" md:w-[325px] lg:w-[400px] mb-[-60px] mr-[-120px] md:top-[65px] md:mr-0 xl:top-0"
                gradientDirection="to-bottom"
                imagePlacement="bottom"
            />
        </PageContainer>
    );
}

