import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
    CardFooter,
} from "src/components/card/Card";
import { cn } from "src/helpers/cn";
import { Link } from "react-router-dom";

interface ICardWithImageProps {
    imageSrc: string;
    title: string;
    description: string;
    buttonText: string;
    anchorHref: string;
    cardFooterContent?: React.ReactElement;
    variant?: "primary" | "secondary";
    imageClassname?: string;
    gradientDirection?: "to-right" | "to-bottom" | "none";
    imagePlacement?: "top" | "bottom";
}

export const CardWithImage = ({
    imageSrc,
    anchorHref,
    buttonText,
    title,
    description,
    imageClassname,
    cardFooterContent,
    variant = "primary",
    gradientDirection = "none",
    imagePlacement = "top",
}: ICardWithImageProps) => {
    const primaryVariant = {
        image: "w-full md:w-[300px] lg:w-[400px] xl:w-[490px] h-auto md:absolute top-0 bottom-0 right-0",
        cardTitle: "w-full text-[28px] leading-[36px] md:text-3xl lg:text-[54px] lg:leading-[66px] lg:w-[20ch] xl:text-[60px] xl:leading-[78px]",
        cardDescription: "text-brand-primary-100 w-full text-[20px] leading-[28px] lg:w-[30ch] xl:w-[45ch]",
        cardHeader: "pt-0 lg:pt-6",
        cardWrapper: "lg:px-[64px]",
    };

    const secondaryVariant = {
        image: "max-w-full w-[300px] lg:w-[350px] xl:w-[400px] h-full md:absolute top-0 bottom-0 right-0",
        cardTitle: "w-full text-2xl lg:text-3xl md:w-[25ch] lg:w-[30ch] xl:text-4xl",
        cardDescription: "text-brand-primary-300 w-full text-sm lg:text-lg md:w-[40ch] lg:w-[40ch] xl:w-[50ch]",
        cardHeader: "",
        cardWrapper: "lg:px-10",
    };

    const variantStyles = variant === "primary" ? primaryVariant : secondaryVariant;

    const gradient = gradientDirection === "none"
        ? "bg-brand-primary"
        : gradientDirection === "to-right"
            ? "bg-gradient-to-r from-brand-primary from-40% lg:from-60% to-support-purple-300"
            : "bg-gradient-to-b from-brand-primary from-40% lg:from-60% to-support-purple-300";

    const imageElement = (
        <div className={cn(variantStyles.image, imageClassname)}>
            <img
                src={imageSrc}
                alt="Card content image"
                className="relative md:absolute top-0 md:bottom-0 md:top-[unset] xl:top-0 right-0 w-full h-auto object-cover"
            />
        </div>
    );

    return (
        <Card
            className={cn(
                "flex flex-col justify-center items-center md:justify-start md:items-start w-full relative pt-0 pb-0 px-8 md:pt-8 md:pb-8 min-h-[270px] border-0 text-card overflow-hidden bg-brand-primary",
                gradient,
                variantStyles.cardWrapper
            )}
        >
            {imagePlacement === "top" && imageElement}

            <CardHeader className={cn("max-w-[70ch] gap-4 px-0", variantStyles.cardHeader)}>
                <CardTitle className={variantStyles.cardTitle}>{title}</CardTitle>
                <CardDescription className={cn(variantStyles.cardDescription)}>{description}</CardDescription>
            </CardHeader>
            <CardContent className="w-full px-0">
                <Link
                    to={anchorHref}
                    className="w-full md:w-52 text-brand-primary p-2 border border-input bg-background inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
                >
                    {buttonText}
                </Link>
            </CardContent>
            {cardFooterContent && <CardFooter className="mt-8">{cardFooterContent}</CardFooter>}

            {imagePlacement === "bottom" && imageElement}
        </Card>
    )
}