import { Link, useRouteMatch } from "react-router-dom";
import { IRelativeLinkProps } from "src/components/relative-link/types";

function RelativeLink({ to, ...rest }: IRelativeLinkProps) {
    const match = useRouteMatch();

    return <Link to={`${match.url}${to}`} {...rest} />;
}

export default RelativeLink;
