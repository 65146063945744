import { Headset } from "lucide-react";

export const SupportCallToAction = () => (
    <a
        href="https://help.peergame.com/"
        className="flex gap-2 items-center text-brand-white p-2 rounded-[10px] border border-brand-white"
    >
        <Headset />
        Help Center
    </a>
)