import {EventEmitter} from "eventemitter3";
import { CallbackFn } from "@ntropy/utils/src/typescript-helpers";
import { LocalStorageKey } from "src/core/local-storage/local-storage.model";

export type LocalStorageType = object | any[] | string | number | boolean | null;

class LocalStorageService {
    private _localStorage: Storage = window.localStorage;

    private eventEmitter = new EventEmitter<LocalStorageKey>();

    private listenersFor: Partial<Record<LocalStorageKey, number>> = {};

    private parseEntry = (dataAsString: string | null, key?: LocalStorageKey) => {
        if (dataAsString === null) {
            return null;
        }

        let obj: LocalStorageType | null = null;

        try {
            obj = JSON.parse(dataAsString) as LocalStorageType;
        } catch (error) {
            if (key) {
                console.error(`Could not parse object from localstorage under ${key} key`);
            }

            return null;
        }

        return obj;
    };

    get<T extends LocalStorageType>(key: LocalStorageKey) {
        const dataAsString = this._localStorage.getItem(key);

        return this.parseEntry(dataAsString, key) as T | null;
    }

    set<T extends LocalStorageType = LocalStorageType>(key: LocalStorageKey, object: T | null) {
        const jsonString = JSON.stringify(object);

        this._localStorage.setItem(key, jsonString);

        this.eventEmitter.emit(key, object);
    }

    remove(key: LocalStorageKey) {
        this._localStorage.removeItem(key);

        this.eventEmitter.emit(key, null);
    }

    clear() {
        this._localStorage.clear();

        for (const [key, listenersCount] of Object.entries(this.listenersFor) as [LocalStorageKey, number][]) {
            if (listenersCount > 0) this.eventEmitter.emit(key, null);
        }
    }

    on = <T extends LocalStorageType = LocalStorageType>(key: LocalStorageKey, callback: CallbackFn<[T]>) => {
        this.listenersFor[key] = (this.listenersFor[key] ?? 0) + 1;

        this.eventEmitter.on(key, callback);
    };

    once = <T extends LocalStorageType = LocalStorageType>(key: LocalStorageKey, callback: CallbackFn<[T]>) => {
        this.listenersFor[key] = (this.listenersFor[key] ?? 0) + 1;

        this.eventEmitter.once(key, v => {
            callback(v);
            this.listenersFor[key] = (this.listenersFor[key] ?? 0) - 1;
        });
    };

    off = <T extends LocalStorageType = LocalStorageType>(key: LocalStorageKey, callback: CallbackFn<[T]>) => {
        this.listenersFor[key] = Math.max((this.listenersFor[key] ?? 0) - 1, 0);

        this.eventEmitter.off(key, callback);
    };
}

const localStorageService = new LocalStorageService();

export default localStorageService;
