import { CallbackFn } from "@ntropy/utils/src/typescript-helpers";
import { useSignalRClient } from "src/core/signalr/hooks/useSignalRClient";
import { useRefValue } from "@ntropy/hooks/src/state/useRefValue";
import { useEffect } from "react";
import { SignalREvent } from "../signalr.model";

interface IUseSignalREventHandlerInput {
    enabled?: boolean
}

export function useSignalREventHandler<TEventData>(
    eventName: SignalREvent, handler: CallbackFn<[TEventData]>,
    { enabled = true }: IUseSignalREventHandlerInput = {},
) {
    const {client} = useSignalRClient();

    const handlerRef = useRefValue(handler);

    useEffect(() => {
        if (!enabled) {
            return;
        }

        const singleHandler = (...args: any) => {
            const eventData = args.length === 1 ? args[0] : args;

            handlerRef.current(eventData);
        }

        client.on(eventName, singleHandler);

        return () => {
            client.off(eventName, singleHandler)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventName, enabled]);
}