import { GameCode, InHouseGame } from "src/core/casino/casino.model";

export default function useModulusBy(gameCode: GameCode) {
    switch (gameCode) {
        case InHouseGame.Roulette:
            return 37;
        case InHouseGame.Dice:
            return 10000;
        case InHouseGame.Coinflip:
            return 2;
        case InHouseGame.Wheel:
            return 54;
        case InHouseGame.Turtle:
            return 6;
        case InHouseGame.Ladder:
            return 4;
        default:
            return 2;
    }
}
