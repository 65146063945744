import { Link } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./styles.scss";
import IconPlay from "./play-circle.svg?inline";
import { Ban } from "lucide-react";

interface IGameThumbnailProps {
    title: string;
    url: string;
    thumbnailSrc: string;
    provider: string;
    isDisabled: boolean;
    className?: string;
}

const cx = classNames.bind(styles);

export default function GameThumbnail({
    title,
    url,
    thumbnailSrc,
    provider,
    isDisabled,
    className,
}: IGameThumbnailProps) {
    const content = <>
        <img src={thumbnailSrc} alt={title} />
        <div className={cx("hover-overlay")}>
            <span>{title}</span>
            {isDisabled ? <Ban className="w-10 h-10" /> : <IconPlay />}
            <span className={cx("small")}>{provider}</span>
        </div>
    </>

    return (
        <>
            {isDisabled ? <div className={cx("thumbnail", "grayscale cursor-not-allowed", className)}>{content}</div> :
                <Link to={url} className={cx("thumbnail", className)}>{content}</Link>
            }
        </>
    );
}