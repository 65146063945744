import { useState, useEffect } from "react";
import classNames from "classnames/bind";
import _isEmpty from "lodash/isEmpty";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { StringParam, useQueryParams, NumberParam } from "use-query-params";
import Input from "src/components/input/Input";
import styles from "./assets/styles.scss";
import Detail from "src/components/detail/Detail";
import Loader from "src/components/loader3/Loader";
import { axiosInstance } from "src/core/http/axios-instance";
import config from "src/config";
import CoinBattles from "src/containers/FairnessCalculation/layouts/CoinBattles";
import Baccarat from "src/containers/FairnessCalculation/layouts/Baccarat";
import Blackjack from "src/containers/FairnessCalculation/layouts/Blackjack";
import Default from "src/containers/FairnessCalculation/layouts/Default";
import { games } from "src/games/config";
import { GameCode, GameCodeParam, InHouseGame } from "src/core/casino/casino.model";
import { useDebounced } from "@ntropy/hooks/src/callback/useDebounced";

const cx = classNames.bind(styles);

interface IFairnessCalculationData {
    timestamp: number;
    data: {
        game_code: GameCode;
        [key: string]: any;
    }[]
}

const getResults = async (txId: string, gameId: number | undefined | null, gameCode: GameCode | "") => {
    let payload: object;

    if (gameId && gameCode) {
        payload = { gameId, game: gameCode };
    } else if (txId) {
        payload = { txid: txId };
    } else {
        return undefined;
    }

    const { data } = await axiosInstance.get<IFairnessCalculationData>("/v1/fairness/search", { params: { asset: config.asset, ...payload } });
    return data[0] || data;
};

const FairnessCalculation = () => {
    const [query, setQuery] = useQueryParams({
        txId: StringParam,
        gameId: NumberParam,
        gameCode: GameCodeParam,
    });
    const { txId, gameId, gameCode } = query;
    const [inputValue, setInputValue] = useState(txId || "");
    const queryClient = useQueryClient();
    const queryKey = ["fairness", txId, gameCode, gameId];
    const { data = { game_code: "" }, isLoading } = useQuery({
        queryKey,
        queryFn: () => getResults(txId || "", gameId, gameCode || ""),
        enabled: !!txId || (!!gameId && !!gameCode),
    });

    const debounced = useDebounced(
        () => {
            if (inputValue) {
                if (query.gameId && query.gameCode) {
                    setQuery({ gameId: undefined, gameCode: undefined });
                }
            } else if (!query.gameCode) {
                queryClient.removeQueries({ queryKey });

                if (query.gameId) {
                    setQuery({ gameId: undefined });
                }
            }

            setQuery({ txId: inputValue });
        },
        1000,
    );

    useEffect(() => {
        debounced();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    function getLayoutComponent() {
        switch (data.game_code) {
            case InHouseGame.CfBattle:
                return <CoinBattles data={data as any} gameId={gameId || null} setGameId={newGameId => setQuery({ gameId: newGameId })} />;
            case InHouseGame.Baccarat:
                return <Baccarat data={data as any} />;
            case InHouseGame.Blackjack:
                return <Blackjack data={data as any} />;
            default:
                return <Default data={data as any} />;
        }
    }

    function getContent() {
        if (isLoading) {
            return <Loader />;
        }
        if (txId && _isEmpty(data)) {
            return (
                <span className={cx("small", "retry")}>
                    Round Seed hasn't revealed yet. <br /> Please try again later
                </span>
            );
        }

        return (
            <>
                <h6>Calculation Breakdown</h6>
                <Detail
                    contentClassName={cx("detail-content")}
                    className={cx("detail")}
                    label="Game"
                    content={games[data.game_code]?.label}
                />
                {getLayoutComponent()}
            </>
        );
    }

    return (
        <div className={cx("calculator")}>
            <h3>Fairness Calculation</h3>
            <span className={cx("subtitle")}>Please enter your Bet Transaction ID to check the final game result.</span>
            <Input
                className={cx("input-container")}
                label="Bet TxID"
                value={inputValue}
                onChange={newValue => setInputValue(newValue.toString())}
                placeholder="Enter Your TxID"
            />
            {getContent()}
        </div>
    );
};

export default FairnessCalculation;
