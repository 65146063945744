import {useRef} from "react";
import {animated} from "@react-spring/web";
import PropTypes from "prop-types";
import {v4 as uuidv4} from "uuid";

export default function Animated1ChipSVG({applyRotation, ...rest}) {
    const rotation = useRef(applyRotation ? Math.floor(Math.random() * 360) : 0);
    const randomId = uuidv4();

    return (
        <animated.svg {...rest} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 50">
            <g clipPath={`url(#clip-chip-1-${randomId})`} filter={`url(#filter-chip-1-${randomId})`}>
                <path
                    fill="#FF3B3B"
                    d="M40.97 40.97c9.373-9.372 9.373-24.568 0-33.94-9.372-9.373-24.568-9.373-33.94 0-9.373 9.372-9.373 24.568 0 33.94 9.372 9.373 24.568 9.373 33.94 0z"
                />
                <path
                    style={{transform: `rotate(${rotation.current}deg)`}}
                    fill="#FFB9B9"
                    d="M9.866 4.601a24.13 24.13 0 00-2.721 2.313l-.022.021a24.131 24.131 0 00-2.34 2.685l4.827 4.887 2.539-2.506L14.69 9.49 9.866 4.6zM.31 20.121a24.121 24.121 0 00-.309 3.549v.035a24.207 24.207 0 00.218 3.56l6.87.085.046-3.567.046-3.573-6.87-.088zM9.37 33.119l-4.951 4.761a24.126 24.126 0 004.952 5.148l4.95-4.763-2.473-2.57-2.478-2.576zm10.62 7.65l-.177 6.868c2.358.413 4.765.475 7.141.184l.174-6.87-3.566-.09-3.572-.092zm18.146-6.902l-2.603 2.44-2.606 2.444 4.697 5.012a24.14 24.14 0 005.211-4.885l-4.698-5.01-.001-.001zm2.855-12.77l-.138 3.565-.137 3.57 6.864.265a24.05 24.05 0 00.402-3.541v-.022a24.23 24.23 0 00-.126-3.575l-6.866-.262zM39.122 5.362l-5.071 4.633 2.405 2.634 2.41 2.64 5.073-4.632a24.13 24.13 0 00-4.817-5.274zM24 0c-1.197 0-2.392.088-3.576.265l.002 6.87h4.017l.439.022 3.569.183.353-6.86A24.11 24.11 0 0024 0z"
                />
                <path
                    fill="#FFC6C6"
                    d="M37.218 37.217c7.3-7.3 7.3-19.135 0-26.435-7.3-7.3-19.136-7.3-26.435 0-7.3 7.3-7.3 19.135 0 26.435 7.3 7.3 19.135 7.3 26.435 0z"
                />
                <path
                    fill="#FFDBDB"
                    d="M35.45 35.467c6.335-6.336 6.337-16.607.002-22.94-6.334-6.335-16.605-6.334-22.94.001-6.336 6.336-6.337 16.607-.003 22.941s16.605 6.333 22.941-.002z"
                />
                <path
                    style={{transform: `rotate(${rotation.current}deg)`}}
                    fill="#360202"
                    d="M23.227 31v-9.14h-1.244V20.05a5.6 5.6 0 01.513-.267c.214-.105.38-.189.5-.253.118-.064.25-.168.396-.314.145-.146.268-.321.369-.526h1.818V31h-2.352z"
                />
            </g>
            <defs>
                <filter
                    id={`filter-chip-1-${randomId}`}
                    width="48"
                    height="52"
                    x="0"
                    y="0"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dy="2"/>
                    <feColorMatrix values="0 0 0 0 0.783333 0 0 0 0 0.1175 0 0 0 0 0.1175 0 0 0 1 0"/>
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
                <clipPath id={`clip-chip-1-${randomId}`}>
                    <path fill="#fff" d="M0 0H48V50H0z"/>
                </clipPath>
            </defs>
        </animated.svg>
    );
}
Animated1ChipSVG.propTypes = {
    applyRotation: PropTypes.bool,
};
Animated1ChipSVG.defaultProps = {
    applyRotation: false,
};
