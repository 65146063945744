import { CryptoCurrency } from "src/core/currency/currency.model";
import { enumToValues } from "@ntropy/utils/src/enum-utils";

export type WalletAssetBalance = { amount: number, asset: CryptoCurrency };

export interface IWalletBalanceResponse {
    timestamp: number;
    data: WalletAssetBalance[]
}


export interface IWalletResponse {
    default: boolean
    name: CvApiWalletProvider
    sendable: boolean
    paymail?: string
}

export interface IWalletTopupResponse {
    defaultWallet: CvApiWalletProvider
    uri: string
}

export const DeprecatedMoneyButtonProvider = "moneybutton" as const;
export type DeprecatedMoneyButtonProvider = typeof DeprecatedMoneyButtonProvider;

export type CvApiWalletProvider = WalletProvider.HandCash | DeprecatedMoneyButtonProvider;

export enum WalletProvider {
    WalletConnect = "walletconnect",
    HandCash = "handcash"
}

const supportedWalletProviders = enumToValues(WalletProvider);

export const isSupportedWalletProvider = (anyWalletProvider: WalletProvider | DeprecatedMoneyButtonProvider | null | undefined): anyWalletProvider is WalletProvider => {
    return supportedWalletProviders.includes(anyWalletProvider as any);
}