import { useHandleGames } from "src/containers/Landing/hooks/useHandleGames";
import { ToggleGroup, ToggleGroupItem } from "src/components/toggle-group/ToggleGroup";
import { gameSections } from "src/containers/Landing/constants/gameSections";
import { BasicCarousel } from "src/components/basic-carousel/BasicCarousel";
import { CasinoGameCategory } from "src/core/casino/casino.model";
import { useState } from "react";
import { SkeletonCard } from "src/components/skeleton-card/SkeletonCard";
import { useDesktopOnly } from "src/helpers/responsive";
import { CarouselItem } from "src/components/carousel/Carousel";

export const TrendingGames = () => {
    const isDesktopOnly = useDesktopOnly();
    const { getGamesThumbnail, isLoading } = useHandleGames();

    const [selectedCategory, setSelectedCategory] = useState(CasinoGameCategory.InHouse)

    const currentGameSection = gameSections.find(({ category }) => selectedCategory === category);

    return (
        <section>
            <div className=" flex flex-col gap-4">
                <h2 className="text-[28px] leading-5 text-white">Trending Games</h2>
                <h3 className="text-brand-primary-300 text-lg max-w-[50ch] font-normal">Explore the most popular casino games on PeerGame. Discover what players
                    love and start
                    your
                    winning streak today!</h3>
            </div>

            <div className="flex items-center mt-8 mb-4 gap-4 border-b border-brand-primary-300 py-2">
                <ToggleGroup type="single">
                    {gameSections.map(({ title, category }) => (
                        <ToggleGroupItem
                            data-state={category === selectedCategory ? "on" : "off"}
                            aria-checked={category === selectedCategory}
                            key={category}
                            value={category}
                            aria-label={`Toggle ${category}`}
                            onClick={() => setSelectedCategory(category)}
                        >
                            {title}
                        </ToggleGroupItem>
                    ))}
                </ToggleGroup>
            </div>

            <BasicCarousel>
                {isLoading ? Array.from({ length: currentGameSection?.limitSize(isDesktopOnly) || 10 }).map((_, i) => (
                    <CarouselItem key={i} className="basis-[66%] md:basis-1/4 lg:basis-1/6">
                        <SkeletonCard />
                    </CarouselItem>
                )) : getGamesThumbnail(selectedCategory)
                }
            </BasicCarousel>
        </section>
    )
}