import { createWeb3Modal } from "@web3modal/wagmi/react"
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config"

import { arbitrum, mainnet } from "wagmi/chains"

// 1. Create wagmiConfig
const hostToProjectMap = {
    "satocash.com": "428b9ecf64b9d839b608b217fabc82cc",
    "localweb.satocash.com": "428b9ecf64b9d839b608b217fabc82cc",
}

const host = window.location.host;

const metadata = {
    name: "ntropy-test",
    description: "AppKit Example",
    url: `https://${host}/`, // origin must match your domain & subdomain
    icons: ["https://avatars.githubusercontent.com/u/37784886"],
}

const chains = [mainnet, arbitrum] as const

export const wagmiConfig = defaultWagmiConfig({
    chains,
    projectId: hostToProjectMap[host],
    metadata,
    auth: {
        email: false,
    },
})

// 2. Create modal
createWeb3Modal({
    wagmiConfig,
    projectId: hostToProjectMap[host],
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    enableOnramp: true, // Optional - false as default
})