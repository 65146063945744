import classNames from "classnames/bind";
import styles from "./assets/styles.scss";

const cx = classNames.bind(styles);

function Loader() {
    return (
        <div className={cx("loader-container")}>
            <div className={cx("loader")}>
                <div />
                <div />
            </div>
        </div>
    );
}

export default Loader;
