import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./assets/styles.scss";

const cx = classNames.bind(styles);

export default function Info({goBack}) {
    return (
        <div className={cx("info")}>
            <p>
                Game Balance is a new feature developed by Peergame.
                <br/>
                You can instantly charge your game balance from your wallet and start to play.
                <br/>
                After you are done with playing, Peergame will directly send the remaining balance to your wallet.
                <br/>
                The deposited balance will be available only for Blackjack, Slots and Third party Table games
                <br/>
                If you are out of balance, no problem.
                <br/>
                You can recharge your game balance any time you want.
            </p>
            <button type="button" onClick={goBack} className={cx("bold")}>
                Cool, Understand
            </button>
        </div>
    );
}
Info.propTypes = {
    goBack: PropTypes.func.isRequired,
};
Info.defaultProps = {};
