import { HubConnectionBuilder, HubConnection, LogLevel, DefaultHttpClient, HttpRequest, HttpResponse } from "@microsoft/signalr";
import { delayFor } from "@ntropy/utils/src/promise-utils";
import { CallbackFn } from "@ntropy/utils/src/typescript-helpers";
import { EventEmitter } from "eventemitter3";
import appConfig from "src/config";
import { SignalREvent } from "src/core/signalr/signalr.model";
import localStorageService from "src/core/local-storage/local-storage.service";
import { LocalStorageKey } from "src/core/local-storage/local-storage.model";
import { IHttpConnectionOptions } from "@microsoft/signalr/src/IHttpConnectionOptions";
import { PgTokenHeaderName } from "src/core/http/http.const";

export enum SocketConnectionStatus {
    Connecting = 0,
    Open = 1,
    Closed = 2,
}

const getPgToken = () => localStorageService.get<string>(LocalStorageKey.SessionToken)!;

class CustomHttpClient extends DefaultHttpClient {
    constructor() {
        super(console);
    }

    public send(request: HttpRequest): Promise<HttpResponse> {
        const token = getPgToken();

        if (token) {
            request.headers = { ...request.headers, [PgTokenHeaderName]: token };
        }

        return super.send(request);
    }
}

class SignalRClient {
    #connection: HubConnection;
    #eventEmitter = new EventEmitter<"connection-status" | "message-send" | "reconnected">();

    constructor() {
        this.#connection = new HubConnectionBuilder()
            .configureLogging(LogLevel.Warning)
            .withUrl(`${appConfig.apiHost}/cashier/wspg`, {
                httpClient: new CustomHttpClient(),
                accessTokenFactory: getPgToken,
            } satisfies IHttpConnectionOptions)
            .withAutomaticReconnect()
            .build();
        this.onConnectionStatus(this.#setConnectionStatus);
    }

    started = false;

    start = async () => {
        try {
            this.started = true;
            await this.#connection.start();
            this.#eventEmitter.emit("connection-status", SocketConnectionStatus.Open)
        } catch (e) {
            console.error("SignalRClient start error", e);
            this.started = false;
            await delayFor(300);
            this.start();
            return;
        }

        this.#connection.onclose(() => this.#eventEmitter.emit("connection-status", SocketConnectionStatus.Closed));
        this.#connection.onreconnecting(() => this.#eventEmitter.emit("connection-status", SocketConnectionStatus.Connecting));
        this.#connection.onreconnected(() => {
            this.#eventEmitter.emit("reconnected");
            this.#eventEmitter.emit("connection-status", SocketConnectionStatus.Open)
        });
    }

    stop = () => {
        this.started = false;
        this.#connection.stop();
    }

    connectionStatus = SocketConnectionStatus.Connecting;

    #setConnectionStatus = (connectionStatus: SocketConnectionStatus) => {
        this.connectionStatus = connectionStatus;
    }

    onConnectionStatus = (callback: CallbackFn<[SocketConnectionStatus]>) => {
        this.#eventEmitter.on("connection-status", callback);
    }

    offConnectionStatus = (callback: CallbackFn<[SocketConnectionStatus]>) => {
        this.#eventEmitter.off("connection-status", callback);
    }

    on = <TData>(eventName: SignalREvent, callback: (data: TData) => void) => {
        this.#connection.on(eventName, callback);
    }

    off = <TData>(eventName: SignalREvent, callback?: (data: TData) => void) => {
        if (callback) {
            this.#connection.off(eventName, callback);
        } else {
            this.#connection.off(eventName);
        }
    }
}

export default SignalRClient;