import { WalletConnectButton } from "src/feature/auth/components/wallet-connect-button/WalletConnectButton";
import config from "src/config";
import { useMaintenance } from "src/core/maintenance/hooks/useMaintenance";
import { WalletProvider } from "src/core/wallet/wallet.model";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "src/components/dialog/Dialog";
import { useSingleState } from "@ntropy/hooks";
import bg from "src/feature/auth/assets/images/auth-modal-bg.png";
import AuthActionButton from "src/feature/auth/components/AuthActionButton";
import { useMobileOnly } from "src/helpers/responsive";
import { getLoginUrl } from "src/core/auth/auth.functions";

export const useAuthModalState = () => {
    return useSingleState("auth-state", false);
}


export default function AuthModal() {
    const [isOpen, setModalOpen] = useAuthModalState()

    const isMobileOnly = useMobileOnly();

    const walletConnectMaintenance = useMaintenance({ forWallet: WalletProvider.WalletConnect });
    const handCashMaintenance = useMaintenance({ forWallet: WalletProvider.HandCash });

    const providerButtons = <div className="flex flex-col gap-4 items-stretch">
        {!walletConnectMaintenance && config.enableWalletConnect &&
            <WalletConnectButton closeBaseAuthModal={() => setModalOpen(false)} />
        }
        {!handCashMaintenance &&
            <AuthActionButton walletProvider={WalletProvider.HandCash} onClick={() => {window.location.href = getLoginUrl(WalletProvider.HandCash)}} />
        }
    </div>

    return (
        <Dialog open={isOpen} onOpenChange={setModalOpen}>
            <DialogContent slimPadding className="max-w-xl max-h-full md:max-h-[80%] flex gap-6">
                {!isMobileOnly && <img className="h-full aspect-auto sm:my-[-16px] sm:ml-[-16px]" src={bg} alt="play now" />}
                <div>
                    <DialogHeader>
                        <DialogTitle className="text-left inline-flex flex-row text-[28px] mb-6 leading-[36px]">
                            Play Now
                        </DialogTitle>
                    </DialogHeader>

                    <p className="font-md leading-[22px] mb-6">
                        Simply connect your wallet to enable blockchain transactions and start playing with your privacy protected.
                    </p>
                    {providerButtons}
                </div>
            </DialogContent>
        </Dialog>
    );
}