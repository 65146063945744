import classNames from "classnames/bind";
import styles from "./styles.scss";
import TableWrapper, { ITableColumn } from "src/components/table-wrapper/TableWrapper";
import { useTransactions } from "src/containers/Account/hooks/useTransactions/useTransactions";
import { TransactionType, TransactionStatus } from "src/containers/Account/hooks/useTransactions/transactions.model";
import upperFirst from "lodash/upperFirst";
import CurrencyAmount from "src/components/currency-amount/CurrencyAmount";
import { FiatCurrency } from "src/core/currency/currency.model";
import { isSet } from "@ntropy/utils/src/type-utils";

const cx = classNames.bind(styles);

interface ITransactionsProps {
    type: TransactionType
    status: TransactionStatus | ""
}

export default function TransactionsTable({ type, status }: ITransactionsProps) {
    const {
        data = { pages: [] },
        fetchNextPage,
        hasNextPage,
        isFetching,
        isLoading,
    } = useTransactions({ type, status });

    const flatData = data.pages.flat();

    const columns: ITableColumn[] = [{ label: "Time" }, { label: "Type" }, { label: "Status" }, { label: "Amount" }];

    return (
        <TableWrapper
            isLoading={isLoading}
            columns={columns}
            className={cx("history-table")}
            infiniteScrollProps={{
                loadMore: () => fetchNextPage(),
                hasMore: !isFetching && hasNextPage,
            }}
            emptyPlaceholder={!flatData.length ? "You do not have any transactions" : ""}
        >
            {flatData.map(({ transactionId, timeFormatted, type, status, amount, amountInUsd, currency }) => {

                return (
                    <tr key={transactionId} className={cx("small")}>
                        <td>{timeFormatted}</td>
                        <td>{type.toUpperCase() === "DEPOSIT" ? "Top-Up" : "Cashout"}</td>
                        <td>{upperFirst(status)}</td>
                        <td>
                            <CurrencyAmount className={cx("bold", status.toLowerCase())} amount={amount} currency={currency} />
                            {isSet(amountInUsd) && <>
                                {" ("}
                                <CurrencyAmount className={cx("bold", status.toLowerCase())} amount={amountInUsd} currency={FiatCurrency.USD} />
                                {")"}
                            </>}
                        </td>
                    </tr>
                );
            })}
        </TableWrapper>
    );
}
