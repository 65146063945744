import { forwardRef } from "react";
import CurrencyIcon from "src/components/currency-icon/CurrencyIcon";
import BigNumber from "bignumber.js";
import { CryptoCurrency } from "src/core/currency/currency.model";
import { formatCrypto, formatFiat } from "src/core/currency/currency.function";
import { IColorChange } from "src/components/header/hooks/useHandcashWalletBalance";
import { useMobileOnly } from "src/helpers/responsive";
import cn from "classnames";
import { useConvertToUsd } from "src/core/currency/hooks/useConvertToUSD";
import { ChevronDown } from "lucide-react";
import { CasinoGameCategory, InHouseGame, GameCode } from "src/core/casino/casino.model";

interface IWalletMenuTriggerProps {
    className: string
    onClick(): void
    balance: BigNumber | undefined
    depositBalance: BigNumber | undefined
    asset: CryptoCurrency
    colorChange: IColorChange
    gameCode: GameCode | null | undefined // replace with enum
    isMenuOpen: boolean
    withWalletButton: boolean
    showUsd: boolean
}

const WalletMenuTrigger = forwardRef<HTMLButtonElement, IWalletMenuTriggerProps>(({
    className,
    onClick,
    balance,
    colorChange,
    asset,
    depositBalance,
    gameCode,
    isMenuOpen,
    withWalletButton,
    showUsd,
}, ref) => {

    const isMobileOnly = useMobileOnly();
    let assetToUse = asset;
    let balanceContent = balance;

    if ([CasinoGameCategory.Table, CasinoGameCategory.Slots, InHouseGame.Blackjack].includes(gameCode!)) {
        balanceContent = depositBalance;
        assetToUse = CryptoCurrency.BSV;
    }

    const usdValue = useConvertToUsd(balanceContent, asset, { enabled: showUsd });

    return (
        <button
            type="button"
            className={cn(
                "group relative overflow-hidden flex items-center justify-end gap-2 px-2 py-3 md:px-4 rounded text-brand-primary-200 bg-brand-primary-500 hover:bg-support-purple-200",
                {
                    "bg-support-purple-200": isMenuOpen,
                    "rounded-r-none": withWalletButton,
                }, className
            )}
            ref={ref}
            onClick={onClick}
            title={`${formatCrypto(balanceContent)} ${assetToUse}`}
        >
            <span
                className={cn("font-bold max-w-[86px] xs:max-w-[105px] min-w-[42px] text-ellipsis overflow-hidden text-right transition-colors", {
                    [colorChange.color ?? ""]: asset === colorChange.asset,
                })}
            >
                {showUsd ? `$\u00A0${formatFiat(usdValue) || "0.00"}` : formatCrypto(balance)}
            </span>
            <CurrencyIcon currency={assetToUse} className="w-6" />
            {!isMobileOnly &&
                <ChevronDown
                    className={cn("h-4 w-4 text:support-purple-100 group-hover:opacity-100 opacity-50 transition-all", { "rotate-180": isMenuOpen })}
                />
            }
        </button>
    )
});

WalletMenuTrigger.displayName = "WalletMenuTrigger";

export default WalletMenuTrigger;