import create from "zustand";
import shallow from "zustand/shallow";
import { BigNumber } from "bignumber.js";
import { Howler } from "howler";
import { devtools } from "zustand/middleware";
import { CryptoCurrency } from "src/core/currency/currency.model";
import { GameCode } from "src/core/casino/casino.model";

interface IBettingData {
    amount: BigNumber;
    script: string;
    multiplier?: number;
}

interface IBettingItem {
    id: number;
    label: string;
    payoutRate: string;
    amount: string | number;
    isWin: boolean;
}

interface IBet {
    game: GameCode;
    timestamp: string;
    txid: string;
    amount: number;
    asset: CryptoCurrency;
    multiplier: number;
    payout: number;
    winner: boolean;
    sendAddress: string;
    player_id: number;
    area: string;
    game_result: string;
    payoutBalance: number;
}

interface GameState {
    isRealMode: boolean;
    setIsRealMode: (isRealMode: boolean) => void;

    bettingData: IBettingData;
    setBettingData: (newData: Partial<IBettingData>) => void;

    bettingHistory: IBettingItem[];
    setBettingHistory: (bettingHistory: IBettingItem[]) => void;

    bettingDisabled: boolean;
    setBettingDisabled: (bettingDisabled: boolean) => void;

    soundDisabled: boolean;
    setSoundDisabled: (soundDisabled: boolean) => void;

    allBets: IBet[];
    setAllBets: (allBets: IBet[]) => void;

    highRollers: IBet[];
    setHighRollers: (highRollers: IBet[]) => void;
}

function loadSoundDisabled(): boolean {
    const soundDisabled: boolean = !!localStorage.getItem("sound") ? JSON.parse(localStorage.getItem("sound") || "") : false;

    Howler.mute(soundDisabled);
    return soundDisabled;
}

export const useGameStore = create<GameState>(
    devtools(
        set => ({
            isRealMode: false,
            setIsRealMode: (isRealMode: boolean) => set({ isRealMode }),

            bettingData: {
                amount: new BigNumber(0),
                script: "",
                multiplier: 1,
            },
            setBettingData: newData => set(state => ({ bettingData: { ...state.bettingData, ...newData } })),

            bettingHistory: [],
            setBettingHistory: bettingHistory => set({ bettingHistory }),

            bettingDisabled: false,
            setBettingDisabled: bettingDisabled => set({ bettingDisabled }),

            soundDisabled: loadSoundDisabled(),
            setSoundDisabled: soundDisabled => {
                Howler.mute(soundDisabled);
                set({ soundDisabled });
            },

            allBets: [],
            setAllBets: allBets => set({ allBets }),

            highRollers: [],
            setHighRollers: highRollers => set({ highRollers }),
        }),
        { name: "Game Store" },
    ),
);

export const useGameShallow = (func: (state: GameState) => any) => useGameStore(func, shallow);
