import { useInfiniteQuery, InfiniteData, QueryKey } from "@tanstack/react-query";
import { InHouseGame, GameCode } from "src/core/casino/casino.model";
import { GameHistoryStatus, GameHistoryEntry } from "src/containers/Account/hooks/useGameHistory/game-history.model";
import { useWalletProvider } from "src/core/wallet/hooks/useWalletProvider";
import { WalletProvider } from "src/core/wallet/wallet.model";
import { getHandcashGameHistory, getCashierGameHistory } from "src/containers/Account/hooks/useGameHistory/game-history.functions";
import type { DefaultError } from "@tanstack/query-core";
import { mapHandcashGameHistoryEntry, mapCashierGameHistoryEntry } from "src/containers/Account/hooks/useGameHistory/game-history.mappers";

interface IUseGameHistoryInput {
    gameCode?: GameCode
    betStatus?: GameHistoryStatus | ""
}

export function useGameHistory({
    gameCode,
    betStatus,
}: IUseGameHistoryInput) {
    const [walletProvider] = useWalletProvider();

    return useInfiniteQuery<GameHistoryEntry[], DefaultError, InfiniteData<GameHistoryEntry>, QueryKey, number | string | null>({
        queryKey: ["my-game-history", gameCode, betStatus, walletProvider],
        initialPageParam: null,
        queryFn: async context => {
            switch (walletProvider) {
                case WalletProvider.HandCash:
                    return (await getHandcashGameHistory(gameCode, { count: 30, betStatus, pageParam: context.pageParam })).map(mapHandcashGameHistoryEntry);
                case WalletProvider.WalletConnect:
                    return (await getCashierGameHistory({ pageSize: 30, lastWagerId: context.pageParam as number | null })).map(mapCashierGameHistoryEntry);
                default: return [] as never;
            }
        },
        getNextPageParam: lastGroup => {
            const lastElement = lastGroup[lastGroup.length - 1];

            if (gameCode === InHouseGame.CfBattle || gameCode === InHouseGame.Blackjack || walletProvider === WalletProvider.WalletConnect) {
                return lastElement && lastElement.id;
            }

            return lastElement && lastElement.roundId;
        },
    });
}