import { useExchangeRates } from "src/core/currency/hooks/useExchangeRates";
import { useCallback } from "react";
import { ICurrencyAccount } from "src/core/currency/currency.model";
import { isSet } from "@ntropy/utils/src/type-utils";

interface IUseSortAccountsByUSDInput {
    enabled?: boolean
}

export function useSortAccountsByUsd({ enabled = true }: IUseSortAccountsByUSDInput = {}) {
    const [rates] = useExchangeRates({ enabled });

    return useCallback((a: ICurrencyAccount, b: ICurrencyAccount) => {
        const rateA = rates?.[a.currencyTicker];
        const rateB = rates?.[b.currencyTicker];

        if (!isSet(rateA) || !isSet(rateB)) {
            return b.balance - a.balance;
        }

        return b.balance * rateB - a.balance * rateA;
    }, [rates]);
}