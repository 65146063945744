import { Component } from "react";


export function setStateAsync<P, S>(that: Component<P, S>) {
    return <K extends keyof S>(
        state:
            | ((prevState: Readonly<S>, props: Readonly<P>) => Pick<S, K> | S | null)
            | (Pick<S, K> | S | null),
    ) => {
        return new Promise<void>((resolve) => that.setState(state, resolve));
    };
}

export const unwrapName = (name: string | null | undefined) => {
    if (!name) {
        return "";
    }

    const [_, withBrackets] = name.match(/\(([^()]*)\)/) ?? [];
    const [withoutBrackets] = name.match(/([^()]*)/) ?? [];

    return withBrackets || withoutBrackets;
};
