import { useContext, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import BigNumber from "bignumber.js";
import { axiosInstance } from "src/core/http/axios-instance";
import useAppStore from "useAppStore";
import GameCodeContext from "containers/Games/GameCodeContext";
import { useAppSocket } from "src/core/application/hooks/useAppSocket";
import { useAuthorizedState } from "src/core/auth/hooks/useAuthorizedState";
import { WalletProvider } from "src/core/wallet/wallet.model";
import { CasinoGameCategory } from "src/core/casino/casino.model";

async function getPending() {
    const { data } = await axiosInstance.get("/v1/deposit/history", {
        params: {
            asset: "BSV",
            task: "DEPOSIT",
            status: "PENDING",
        },
    });
    return data.reduce((accumulator, { amount }) => accumulator.plus(amount), new BigNumber(0));
}

async function getBalance() {
    const { data } = await axiosInstance.get("/v1/deposit/balance", { params: { asset: "BSV" } });
    return new BigNumber(data);
}

async function getSettings() {
    const { data } = await axiosInstance.get("/v1/deposit/setting", { params: { asset: "BSV" } });
    return data;
}

export default function useTableDeposit() {
    const queryClient = useQueryClient();
    const gameCode = useContext(GameCodeContext);
    const {isAuthorized: isAuthorizedWithHandcash} = useAuthorizedState(WalletProvider.HandCash);
    const { data: depositBalance, isLoading } = useQuery({
        queryKey: ["depositBalance"],
        queryFn: getBalance,
        enabled: isAuthorizedWithHandcash,
    });
    const { data: depositSettings, isLoading: isLoadingSettings } = useQuery({
        queryKey: ["depositSettings"],
        queryFn: getSettings,
        enabled: isAuthorizedWithHandcash,
    });
    const { data: pendingAmount, isLoading: isLoadingPending } = useQuery({
        queryKey: ["pendingDeposit"],
        queryFn: getPending,
        enabled: isAuthorizedWithHandcash,
    });

    function setPendingBalance() {
        queryClient.setQueryData(["pendingDeposit"], new BigNumber(1));
    }

    function setBalance(newBalance) {
        queryClient.setQueryData(["depositBalance"], new BigNumber(newBalance));
    }

    const socket = useAppSocket();

    useEffect(() => {
        if (!socket.hasListeners("REWARD")) {
            socket.on("REWARD", data => {
                const { amount } = JSON.parse(data);
                queryClient.setQueryData(["depositBalance"], balance => balance.plus(amount));
            });
        }
        if (!socket.hasListeners("DEPOSIT_COMPLETED")) {
            socket.on("DEPOSIT_COMPLETED", data => {
                const { amount } = JSON.parse(data);
                queryClient.setQueryData(["depositBalance"], balance => balance.plus(amount));
                queryClient.setQueryData(["pendingDeposit"], new BigNumber(0));
                useAppStore.getState().setAssetBalance(currentBalance => ({
                    BSV: currentBalance.BSV.minus(amount),
                }));
            });
        }
    }, []);

    useEffect(() => {
        if (gameCode === CasinoGameCategory.Slots && !socket.hasListeners("PARTICIPATE")) {
            socket.on("PARTICIPATE", data => {
                const { value } = JSON.parse(data);
                queryClient.setQueryData(["depositBalance"], balance => balance.minus(value.amount));
            });
            return () => socket.off("PARTICIPATE");
        }
    }, [gameCode]);

    return {
        depositBalance,
        isLoading: isLoading || isLoadingPending || isLoadingSettings,
        setBalance,
        hasPending: !pendingAmount?.isZero(),
        setPendingBalance,
        settings: depositSettings,
    };
}
