import useAppStore from "src/useAppStore";
import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "src/core/http/axios-instance";
import { ICurrencyAccount, CryptoCurrency } from "src/core/currency/currency.model";
import { Endpoint } from "src/core/http/endpoint";
import localStorageService from "src/core/local-storage/local-storage.service";
import { LocalStorageKey } from "src/core/local-storage/local-storage.model";
import { useSessionToken } from "src/core/auth/hooks/useSessionToken";
import { walletAccountsQueryKey } from "src/core/wallet/hooks/useWalletAccounts/useWalletAccounts.query";

interface IUseGetWalletAccountsInput<TResult = ICurrencyAccount[]> {
    enabled?: boolean
    select?(value: ICurrencyAccount[]): TResult
}

export function useGetWalletAccounts<TResult = ICurrencyAccount[]>({enabled, select}: IUseGetWalletAccountsInput<TResult>) {
    const {loadAssetBalance, setSelectedAsset} = useAppStore();
    const [token] = useSessionToken();

    return useQuery({
        queryKey: walletAccountsQueryKey,
        initialData: [],
        queryFn: async () => {
            const accounts = await axiosInstance.get<ICurrencyAccount[]>(Endpoint.Accounts);

            await loadAssetBalance(accounts.map(a => ({ amount: a.balance, asset: a.currencyTicker })));

            const currentAsset = localStorageService.get<CryptoCurrency>(LocalStorageKey.CurrencyAsset);

            if (currentAsset) {
                setSelectedAsset(currentAsset);
            }

            return accounts;
        },
        enabled: enabled && !!token,
        select,
    });
}