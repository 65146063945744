import config from "src/config";
import { useContext, useEffect } from "react";
import { axiosInstance } from "src/core/http/axios-instance";
import { useGameStore } from "components/game/hooks/useGameStore";
import GameCodeContext from "containers/Games/GameCodeContext";
import { useQuery } from "@tanstack/react-query";
import { InHouseGame } from "src/core/casino/casino.model";

async function getSettings(gameCode) {
    useGameStore.getState().setBettingDisabled(true);
    let url = `/v1/${gameCode}/games/setting`;
    if ([InHouseGame.Ladder, InHouseGame.Turtle, InHouseGame.Baccarat].includes(gameCode)) {
        url = `/${gameCode}/game/info`;
    }
    if ([InHouseGame.CfBattle, InHouseGame.Blackjack].includes(gameCode)) {
        url = `/v1/games/setting?game=${gameCode}`;
    }
    if (gameCode === InHouseGame.Baccarat) {
        url = "/v2/baccarat/games/current/setting";
    }
    return (await axiosInstance.get
    (url, { params: { asset: config.asset } })).data;
}

export default function useSettings(querySettings) {
    const gameCode = useContext(GameCodeContext);
    const { data: settings = {}, isLoading, isError } = useQuery({
        queryKey: ["settings", gameCode],
        queryFn: () => getSettings(gameCode),
        refetchOnMount: false,
        ...querySettings,
    });

    useEffect(() => {
        if (!!settings && !isLoading) {
            useGameStore.getState().setBettingDisabled(false)
        }
    }, [isLoading, settings]);

    const {
        min_bet: minBet,
        max_bet: maxBet,
        max_payout: maxPayout,
        base_participate_balance: defaultBet,
        highroll_min_payout: highrollerMinimum,
        address,
        payout_rate: payoutRate,
        decimal_place: decimalPlaces,
        ...rest
    } = settings;
    return {
        isLoading: isLoading || isError,
        minBet,
        maxBet,
        maxPayout,
        defaultBet,
        highrollerMinimum,
        decimalPlaces,
        address,
        payoutRate,
        ...rest,
    };
}
