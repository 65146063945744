import { useAsync } from "@ntropy/hooks";
import { getWallets } from "src/core/wallet/wallet.reducer";
import { load as loadAffiliate } from "src/core/affiliate/affiliate.reducer";
import useAppStore from "src/useAppStore";
import { useAppDispatch } from "src/redux/reducer";
import { useCallback } from "react";
import { WalletProvider } from "src/core/wallet/wallet.model";
import { useWalletProvider } from "src/core/wallet/hooks/useWalletProvider";
import { useUserProfile } from "src/core/auth/hooks/useUserProfile";

export function useLoadHandcashUserWithAddons() {
    const dispatch = useAppDispatch();

    const [walletProvider] = useWalletProvider();

    const { isUserLoaded } = useUserProfile({ walletProvider: WalletProvider.HandCash });

    const loadHandcashUserWithWalletsAndAffiliate = useCallback(async () => {
        try {
            dispatch(getWallets());
            dispatch(loadAffiliate());
        } catch (e) {
            console.error("Error when loading user", e);
            useAppStore.getState().setAssetIsLoading(false);
        }
    }, [dispatch])

    useAsync(async () => {
        if (walletProvider === WalletProvider.HandCash && isUserLoaded) {
            await loadHandcashUserWithWalletsAndAffiliate();
        }
    }, [walletProvider, isUserLoaded]);
}