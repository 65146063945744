import { lazy, ComponentType, ReactElement } from "react";
import ImgGameBaccart from "../assets/img/games/baccarat.jpg";
import ImgGameLadder from "../assets/img/games/ladder.jpg";
import ImgGameTurtle from "../assets/img/games/turtle.jpg";
import ImgGameDice from "../assets/img/games/dice.jpg";
import ImgGameCoinflip from "../assets/img/games/coinflip.jpg";
import ImgGameWheel from "../assets/img/games/wheel.jpg";
import ImgGameRoulette from "../assets/img/games/roulette.jpg";
import ImgGameCoinflipbattle from "../assets/img/games/coinflip-battle.jpg";
import ImgGameBlackjack from "../assets/img/games/blackjack.jpg";
import { coinSides } from "./Coinflip/config";
import { sectionTypes } from "./Wheel/config";
import { areas, wheelSections } from "./Roulette/config";
import { getValueColour } from "./Roulette/utils";
import { turtleOptions } from "./Turtle/config";
import { ladderSides } from "./Ladder/config";
import { baccaratOptions } from "src/games/baccarat/config";
import IconCoinFlip from "../assets/img/game-small/coinflip.svg?inline";
import IconDice from "../assets/img/game-small/dice.svg?inline";
import IconLadder from "../assets/img/game-small/ladder.svg?inline";
import IconBaccarat from "../assets/img/game-small/baccarat.svg?inline";
import IconRoulette from "../assets/img/game-small/roulette.svg?inline";
import IconTurtleRace from "../assets/img/game-small/turtlerace.svg?inline";
import IconWheel from "../assets/img/game-small/wheel.svg?inline";
import IconBlackjack from "../assets/img/game-small/blackjack.svg?inline";
import PlayerPick from "./Blackjack/PlayerPick/PlayerPick";
import { getMultiplierFromBet } from "src/games/Dice/config";
import { CasinoGameCategory, GameCode, InHouseGame } from "src/core/casino/casino.model";

export type GameConfig<TPick = any, TResult = any, TBet = any> = {
    label: string;
    thumbnail?: string;
    smallIcon?: string;
    formatPick?: (pick: TPick) => string | ReactElement;
    formatResult?: (result: TResult) => string;
    rewardToastDelay?: number;
    mapMyBets?: (bet: TBet) => any;
    component: ComponentType<any>;
    pgpDisabled?: boolean;
    urlName?: string;
};

export const games: Omit<Record<GameCode, GameConfig>, CasinoGameCategory.InHouse> = {
    [InHouseGame.Blackjack]: {
        label: "Blackjack",
        thumbnail: ImgGameBlackjack,
        smallIcon: IconBlackjack,
        // eslint-disable-next-line react/prop-types
        formatPick: ({ side, sub_actions: subActions }) => <PlayerPick side={side} subActions={subActions} />,
        formatResult: result => (typeof result === "string" ? `${result[0].toUpperCase()}${result.slice(1)}` : result),
        rewardToastDelay: 7 * 1000,
        component: lazy(() => import("./Blackjack/Blackjack")),
        pgpDisabled: true,
    },
    [InHouseGame.Dice]: {
        label: "Dice",
        thumbnail: ImgGameDice,
        smallIcon: IconDice,
        formatPick: pick => {
            const [value, direction] = pick.split(":");
            return `${direction === "UP" ? ">" : "<"} ${value}`;
        },
        formatResult: result => result.toString(),
        mapMyBets: ({
            gameId, playerPick, payout, betAmount, isWin,
        }) => ({
            id: gameId,
            label: playerPick.label,
            amount: isWin ? payout : -betAmount,
            isWin,
            payoutRate: getMultiplierFromBet(playerPick.value),
        }),
        component: lazy(() => import("./Dice/Dice")),
    },
    [InHouseGame.Wheel]: {
        label: "Wheel",
        thumbnail: ImgGameWheel,
        smallIcon: IconWheel,
        rewardToastDelay: 3200,
        formatPick: pick => sectionTypes[pick].label,
        formatResult: result => sectionTypes[result].label,
        mapMyBets: ({
            gameId, playerPick, payout, betAmount, isWin, payoutRate,
        }) => ({
            id: gameId,
            label: playerPick.label,
            amount: isWin ? payout : -betAmount,
            isWin,
            payoutRate,
        }),
        component: lazy(() => import("./Wheel/Wheel")),
    },
    [InHouseGame.Roulette]: {
        label: "Roulette",
        thumbnail: ImgGameRoulette,
        smallIcon: IconRoulette,
        rewardToastDelay: 6500,
        formatPick: pick => {
            const [type, details] = pick.split(":");
            if (["COLOR", "STRAIGHT", "HIGHLOW", "DOZEN"].includes(type)) {
                return `${areas[type].getNumbersLabel(
                    // eslint-disable-next-line no-restricted-globals
                    !isNaN(details) ? Number(details) : details,
                )}`;
            }
            return `${type[0] + type.slice(1).toLowerCase()}: ${areas[type].getNumbersLabel(
                // eslint-disable-next-line no-restricted-globals
                !isNaN(details) ? Number(details) : details,
            )}`;
        },
        formatResult: result => {
            const color = getValueColour(wheelSections, Number(result));
            return `${result}, ${color[0] + color.slice(1).toLowerCase()}`;
        },
        mapMyBets: ({ gameId, bets }) => bets.map(({
            area, isWin, payout, payoutRate, betAmount,
        }) => ({
            id: `${gameId} - ${area.type} - ${area.details}`,
            label: area.label.slice(0, 8),
            amount: isWin ? payout : -betAmount,
            isWin,
            payoutRate,
        })),
        component: lazy(() => import("./Roulette/Roulette")),
    },
    [InHouseGame.Coinflip]: {
        label: "Coin Flip",
        thumbnail: ImgGameCoinflip,
        smallIcon: IconCoinFlip,
        rewardToastDelay: 1400,
        formatPick: pick => coinSides[pick].label,
        formatResult: result => coinSides[result].label,
        mapMyBets: ({
            gameId, playerPick, payout, betAmount, isWin, payoutRate,
        }) => ({
            id: gameId,
            label: playerPick.label,
            amount: isWin ? payout : -betAmount,
            isWin,
            payoutRate,
        }),
        component: lazy(() => import("./Coinflip/CoinFlip")),
    },
    [InHouseGame.Baccarat]: {
        label: "Baccarat",
        thumbnail: ImgGameBaccart,
        smallIcon: IconBaccarat,
        formatPick: pick => baccaratOptions[pick].label,
        formatResult: result => result.map(area => baccaratOptions[area].label).join(", "),
        rewardToastDelay: 7 * 1000,
        component: lazy(() => import("src/games/baccarat/Baccarat")),
    },
    [InHouseGame.Ladder]: {
        label: "Ladder",
        thumbnail: ImgGameLadder,
        smallIcon: IconLadder,
        formatPick: pick => ladderSides[pick].label,
        formatResult: result => ladderSides[result[2]].label,
        rewardToastDelay: 4 * 1000,
        component: lazy(() => import("./Ladder/Ladder")),
    },
    [InHouseGame.Turtle]: {
        label: "Turtle Race",
        thumbnail: ImgGameTurtle,
        smallIcon: IconTurtleRace,
        formatPick: pick => turtleOptions[pick].label,
        formatResult: result => turtleOptions[result[0]].label,
        urlName: "turtlerace",
        rewardToastDelay: 13 * 1000,
        component: lazy(() => import("./Turtle/Turtle")),
    },
    [InHouseGame.CfBattle]: {
        label: "Coin Battles",
        thumbnail: ImgGameCoinflipbattle,
        rewardToastDelay: 4200,
        formatPick: pick => coinSides[pick].label,
        formatResult: result => coinSides[result].label,
        urlName: "coinbattles",
        component: lazy(() => import("./CoinBattles/CoinBattles")),
    },
    [CasinoGameCategory.Slots]: {
        label: "Slots",
        urlName: ":category/:identifier",
        component: lazy(() => import("src/games/SoftSwiss/SoftSwiss")),
        pgpDisabled: true,
    },
    [CasinoGameCategory.Table]: {
        label: "Table Games",
        urlName: ":category/:identifier",
        component: lazy(() => import("src/games/SoftSwiss/SoftSwiss")),
        pgpDisabled: true,
    },
    [CasinoGameCategory.Casual]: {
        label: "Casual Games",
        urlName: ":category/:identifier",
        component: lazy(() => import("src/games/SoftSwiss/SoftSwiss")),
        pgpDisabled: true,
    },
};

export const statisticsCountOptions = [100, 500, 1000];
