import { useQuery } from "@tanstack/react-query";
import { getHandcashGameHistory } from "src/containers/Account/hooks/useGameHistory/game-history.functions";
import { GameCode } from "src/core/casino/casino.model";

interface IUseMyGameHistoryBetsInput {
    gameCode: GameCode | undefined | null
    enabled: boolean
}

export function useMyGameHistoryBets({
    gameCode,
    enabled,
}: IUseMyGameHistoryBetsInput) {
    const { data: myBets = [], isLoading: myBetsLoading } = useQuery({
        queryKey: ["my-game-history-bets", gameCode],
        queryFn: () => getHandcashGameHistory(gameCode, { count: 15, withGameResultOnly: true }),
        enabled,
        retry: false,
    });

    return [myBets, myBetsLoading] as const;
}