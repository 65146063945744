import { useMutation } from "@tanstack/react-query";
import { successToast } from "src/helpers/toast";
import { useWalletProvider } from "src/core/wallet/hooks/useWalletProvider";
import { WalletProvider } from "src/core/wallet/wallet.model";
import { axiosInstance } from "src/core/http/axios-instance";
import { Endpoint } from "src/core/http/endpoint";
import { queryClient } from "src/core/query/query-client";

interface IUseUpdateAvatarInput {
    onSuccess?(): void
}

export function useUpdateAvatar({onSuccess}: IUseUpdateAvatarInput) {
    const [walletProvider] = useWalletProvider();

    const { mutateAsync: updateAvatar, isPending } = useMutation({
        mutationFn: async (id: number) => {
            switch (walletProvider) {
                case WalletProvider.WalletConnect:
                    await axiosInstance.patch(Endpoint.UserAvatarChange, id);
                    break;
                case WalletProvider.HandCash:
                    await axiosInstance.post("/v1/mypage/modify/profileimage", { image_id: id });
                    break;
            }
        },
        onSuccess: () => {
            onSuccess?.();
            successToast("Avatar updated successfully");
            queryClient.invalidateQueries({ queryKey: ["user-info"], exact: false });
        },
    });

    return [updateAvatar, isPending] as const;
}