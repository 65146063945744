import classNames from "classnames/bind";
import Modal from "src/components/modal/Modal";
import Detail from "src/components/detail/Detail";
import styles from "./assets/styles.scss";

import IconLink from "./assets/link.svg?inline";
import { games } from "src/games/config";
import { CryptoCurrency, HandcashCurrency, FiatCurrency, isFiatCurrency, isCryptoCurrency } from "src/core/currency/currency.model";
import CurrencyIcon from "src/components/currency-icon/CurrencyIcon";
import { GameCode, isExternalGameCategory } from "src/core/casino/casino.model";
import {
    GameHistoryStatus,
    TransactionDetails,
    isHandcashTransactionDetails,
    isCashierTransactionDetails
} from "src/containers/Account/hooks/useGameHistory/game-history.model";
import HandcashTransactionsDetails from "src/containers/Account/History/HandcashTransactionsDetails";
import CurrencyAmount from "src/components/currency-amount/CurrencyAmount";

const cx = classNames.bind(styles);

interface IBetDetailsProps {
    isOpen: boolean;
    onClose: () => void;
    isWin: boolean | null
    status: GameHistoryStatus | undefined;
    betAmount: number;
    payout?: number | null;
    payoutRate: string;
    gameCode: GameCode;
    playerPick: string | JSX.Element;
    result: string;
    currency: HandcashCurrency | FiatCurrency;
    transaction?: TransactionDetails
}

const BetDetails = ({
    isOpen,
    onClose,
    isWin,
    status,
    betAmount,
    payout,
    payoutRate,
    gameCode,
    playerPick,
    result,
    currency,
    transaction,
}: IBetDetailsProps) => {
    let statusText: string | undefined;
    let statusClassName: string | undefined;
    let winLossValue: string | number;
    let winLossClassName: string | undefined;

    if (!result && !isExternalGameCategory(gameCode)) {
        winLossValue = "-";
    } else if (isWin || payout) {
        winLossClassName = "win";
        winLossValue = payout ?? "-";
    } else {
        winLossClassName = "loss";
        winLossValue = -betAmount;
    }

    if (status === GameHistoryStatus.Fail) {
        statusClassName = "red";
        statusText = "Failed Bet";
        winLossClassName = undefined;
    } else if (status === GameHistoryStatus.Pending) {
        statusClassName = "yellow";
        statusText = "Pending Bet";
        winLossClassName = undefined;
    }

    return (
        <>
            <span className={cx("bet-details-button", "bold", "small", statusClassName)}>
                {statusText || <CurrencyAmount amount={winLossValue} amountClassName={cx(winLossClassName)} currency={currency} />}
                {result && <IconLink className={cx("bet-details-button-icon")} />}
            </span>
            <Modal
                header="Bet Details"
                dark
                isOpen={isOpen}
                onClose={onClose}
                className={cx("bet-details-modal")}
                backgroundColor="#1F0D53"
            >
                <img src={games[gameCode].thumbnail} alt={gameCode} />
                <div className={cx("pick-result")}>
                    <Detail label="My Pick:" content={playerPick} contentClassName="small" inline />
                    <Detail label="Result:" content={result || "-"} contentClassName="small" inline />
                </div>
                <Detail
                    label="Bet"
                    className={cx("bet")}
                    content={
                        <CurrencyAmount
                            className={cx("small", "text-icon")}
                            amount={betAmount}
                            currency={currency}
                        />
                    }
                />
                <Detail label="Multiplier" content={payoutRate} contentClassName="small" />
                <Detail
                    label="Payout"
                    className={cx("payout")}
                    content={
                        <CurrencyAmount
                            className={cx("small", "bold", "text-icon", winLossClassName)}
                            amount={winLossValue}
                            currency={currency}
                        />
                    }
                />
                {isHandcashTransactionDetails(transaction) && <HandcashTransactionsDetails transaction={transaction} status={status} />}
                {/* TODO: Add link to Cashier transaction in profile */}
                {isCashierTransactionDetails(transaction) && <></>}
            </Modal>
        </>
    );
}

export default BetDetails;
