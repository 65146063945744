import appConfig from "src/config";
import AxiosInstance from "@pg/api-client";
import { ApiEndpoint } from "src/core/http/endpoint";
import { LocalStorageKey } from "src/core/local-storage/local-storage.model";
import { PgTokenHeaderName } from "src/core/http/http.const";

export const axiosInstance = new AxiosInstance<ApiEndpoint>(appConfig.apiHost, {
    tokenLocalStorageKey: LocalStorageKey.SessionToken,
    tokenHeaderKey: PgTokenHeaderName,
    headers: { "Content-Type": "application/json" },
});