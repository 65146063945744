import info, { IInfoState } from "src/redux/modules/info";
import audio, { IAudioState } from "src/redux/modules/audio";
import walletReducer, { IWalletsState } from "src/core/wallet/wallet.reducer";
import affiliateReducer, { IAffiliateState } from "src/core/affiliate/affiliate.reducer";
import { Action } from "redux";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";
import { IClientMiddlewareAction } from "src/redux/middleware/clientMiddleware";

export default function createReducers(asyncReducers: any) {
    return {
        online: (v = true) => v,
        info,
        audio,
        wallets: walletReducer,
        affiliate: affiliateReducer,
        ...asyncReducers,
    };
}

export interface RootState {
    affiliate: IAffiliateState;
    audio: IAudioState;
    info: IInfoState;
    wallets: IWalletsState;
}

export interface AppDispatch<A extends Action | IClientMiddlewareAction = any> {
    <T extends Exclude<A, Action>>(action: T): ReturnType<T["promise"]>;
    <T extends Exclude<A, IClientMiddlewareAction>>(action: T, ...extraArgs: any[]): T;

}
export const useAppDispatch: () => AppDispatch = useDispatch;

// Typed version of useSelector
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;