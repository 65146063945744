import { useQueryParam, withDefault } from "use-query-params";
import classNames from "classnames/bind";
import Dropdown, { IDropdownItem } from "src/components/dropdown/Dropdown";
import styles from "./styles.scss";
import TransactionsTable from "src/containers/Account/Transactions/TransactionsTable";
import { TransactionTypeParam, TransactionType, TransactionStatus } from "src/containers/Account/hooks/useTransactions/transactions.model";
import { createEnumParam } from "serialize-query-params";
import { enumToValues } from "@ntropy/utils/src/enum-utils";
import { useCurrentUsedApi } from "src/core/application/hooks/useCurrentUsedApi";

const cx = classNames.bind(styles);

const typeOptions = [
    { label: "Deposits", value: TransactionType.Deposit },
    { label: "Withdrawals", value: TransactionType.Withdrawal },
] satisfies IDropdownItem[];

const statusOptions = [
    { label: "All Transactions", value: "" as const },
    { label: "Completed", value: TransactionStatus.Completed },
    { label: "Pending", value: TransactionStatus.Pending },
    { label: "Error", value: TransactionStatus.Error },
] satisfies IDropdownItem[];

export const TransactionStatusParam = createEnumParam([...enumToValues(TransactionStatus), ""]);

export default function Transactions() {
    const {shouldUseCashier} = useCurrentUsedApi();
    const [type, setTask] = useQueryParam("type", withDefault(TransactionTypeParam, typeOptions[0].value));
    const [status, setStatus] = useQueryParam("status", withDefault(TransactionStatusParam, statusOptions[0].value));

    const statusOptionsToUse = shouldUseCashier ? statusOptions : statusOptions.filter(f => f.value !== TransactionStatus.Error);

    return (
        <>
            <div className={cx("header")}>
                <h3>Transactions</h3>
                <div className={cx("filters")}>
                    <Dropdown
                        items={typeOptions}
                        selectedValue={type}
                        onChange={(newValue: TransactionType) => setTask(newValue)}
                    />
                    <Dropdown
                        items={statusOptionsToUse}
                        selectedValue={status}
                        onChange={(newValue: TransactionStatus) => setStatus(newValue)}
                    />
                </div>
            </div>
            <TransactionsTable type={type} status={status} />
        </>
    );
}
