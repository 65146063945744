import { QueryKey } from "@tanstack/react-query";
import { isVoid, isNotVoid } from "@ntropy/utils/src/type-utils";
import { CallbackFn } from "@ntropy/utils/src/typescript-helpers";
import { queryClient } from "src/core/query/query-client";

class QueryHelpers {
    select = <TQueryFnData, Return, Props, TaggedQueryKey extends QueryKey = QueryKey>(
        queryKey: TaggedQueryKey,
        selector: CallbackFn<[TQueryFnData?, Props?], Return>,
        props?: Props,
    ): Return => {
        return selector(queryClient.getQueryData<TQueryFnData>(queryKey), props);
    }

    update = async <TQueryFnData, TaggedQueryKey extends QueryKey = QueryKey>(
        queryKey: TaggedQueryKey,
        updater: CallbackFn<[TQueryFnData], TQueryFnData | void | Promise<TQueryFnData | void>>,
    ) => {
        const queryData = queryClient.getQueryData<TQueryFnData>(queryKey);

        if (isVoid(queryData)) {
            const isQueryEnabled = queryClient.getQueryState(queryKey)?.fetchStatus !== "idle";

            if (isQueryEnabled) {
                console.error(`Can't update query data, it's void. QueryKey: ${queryKey}`)
            }

            return;
        }

        const newQueryData = await updater(queryData);

        if (isNotVoid(newQueryData)) {
            queryClient.setQueryData<TQueryFnData>(queryKey, newQueryData);
        }
    }
}

const queryHelpers = new QueryHelpers();

export default queryHelpers;