import { useState } from "react";
import classNames from "classnames/bind";
import Modal from "src/components/modal/Modal";
import styles from "./assets/styles.scss";

import IconCheckmark from "./assets/checkmark-icon.svg?inline";
import IconCamera from "./assets/camera.svg?inline";
import Button from "src/components/button/Button";
import AvatarImage from "src/components/avatar-image/AvatarImage";
import { useAvatars } from "src/containers/Account/hooks/useAvatars";
import { useUpdateAvatar } from "src/containers/Account/hooks/useUpdateAvatar";
import { useUserProfile } from "src/core/auth/hooks/useUserProfile";
import { useAppeared } from "@ntropy/hooks";

const cx = classNames.bind(styles);

const Avatar = () => {
    const { avatar: currentAvatar } = useUserProfile();

    const [isOpen, setIsOpen] = useState(false);
    const [newAvatar, setNewAvatar] = useState(currentAvatar);

    const avatars = useAvatars({ enabled: isOpen });
    const [updateAvatar, isPending] = useUpdateAvatar({
        onSuccess: () => setIsOpen(false),
    });

    const currentAvatarAppeared = useAppeared(currentAvatar);

    if (currentAvatarAppeared && newAvatar !== currentAvatar) {
        setNewAvatar(currentAvatar);
    }

    if (!isOpen && newAvatar !== currentAvatar) {
        setNewAvatar(currentAvatar);
    }

    const handleSaveAvatar = async () => {
        const selectedAvatar = avatars.find(({ imageUrl }) => imageUrl === newAvatar);

        if (selectedAvatar) {
            await updateAvatar(selectedAvatar.id);
        }
    };

    return (
        <>
            <div className={cx("avatar-container")}>
                <AvatarImage avatar={currentAvatar} />
                <button className={cx("hover-overlay")} type="button" onClick={() => setIsOpen(true)}>
                    <IconCamera />
                </button>
            </div>
            <Modal
                header="Change Avatar"
                isOpen={isOpen}
                onClose={() => !isPending && setIsOpen(false)}
                dark
                backgroundColor="#1F0D53"
                className={cx("change-avatar-modal")}
                footerContent={(
                    <Button
                        className={cx("save-button")}
                        onClick={handleSaveAvatar}
                        isDisabled={currentAvatar === newAvatar}
                        isLoading={isPending}
                    >
                        Save
                    </Button>
                )}
            >
                <div className={cx("avatar-list")}>
                    {avatars.map(({ id, imageUrl }) => (
                        <button key={id} type="button" onClick={() => setNewAvatar(imageUrl)}>
                            <img src={imageUrl} alt={`avatar-${id}`} />
                            {newAvatar === imageUrl && <IconCheckmark />}
                        </button>
                    ))}
                </div>
            </Modal>
        </>
    );
};

export default Avatar;
