import { useInfiniteQuery } from "@tanstack/react-query";
import { TransactionType, TransactionStatus } from "src/containers/Account/hooks/useTransactions/transactions.model";
import { useWalletProvider } from "src/core/wallet/hooks/useWalletProvider";
import { WalletProvider } from "src/core/wallet/wallet.model";
import { getHandcashTransactions, getCashierTransactions } from "src/containers/Account/hooks/useTransactions/transactions.functions";

interface IUseTransactionsInput {
    type: TransactionType
    status: TransactionStatus | ""
}

export function useTransactions({
    type, status,
}: IUseTransactionsInput) {
    const [walletProvider] = useWalletProvider();

    return useInfiniteQuery({
        queryKey: ["my-transactions", type, status, walletProvider],
        queryFn: context => {
            switch (walletProvider) {
                case WalletProvider.WalletConnect:
                    return getCashierTransactions(type, status, context?.pageParam)
                case WalletProvider.HandCash:
                    return getHandcashTransactions(type, status, context?.pageParam)
                default: return [] as never;
            }
        },
        initialPageParam: null,
        getNextPageParam: lastGroup => {
            const lastElement = lastGroup[lastGroup.length - 1];

            return lastElement?.timestampParam;
        },
    });
}