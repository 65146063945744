interface IAvatarImageProps {
    avatar?: string
    className?: string
    isLoading?: boolean
}

const dataOrUrlRegExp = /^(https?:|data:)/;

const AvatarImage = ({
    avatar,
    className,
    isLoading = false,
}: IAvatarImageProps) => {
    if (!avatar || isLoading) {
        return <div className="w-9 h-9 bg-brand-primary-400 rounded-full animate-pulse" />
    }

    if (dataOrUrlRegExp.test(avatar)) {
        return <img className={className} src={avatar} alt="user-avatar" />
    }

    return <div className={className} dangerouslySetInnerHTML={{ __html: avatar }} />;
};

export default AvatarImage;