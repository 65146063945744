import { Redirect } from "react-router-dom";
import Loader from "src/components/loader/Loader";
import { ReactNode } from "react";
import { useAppSelector } from "src/redux/reducer";
import { useAuthorizedState } from "src/core/auth/hooks/useAuthorizedState";
import { useUserProfile } from "src/core/auth/hooks/useUserProfile";

interface IProtectedProps {
    children: ReactNode;
}

const Protected = ({ children }: IProtectedProps) => {
    const { isAuthorized, isAuthorizing } = useAuthorizedState();
    const { isLoading: isUserLoading } = useUserProfile();
    const isAffiliateLoaded = useAppSelector(({ affiliate }) => affiliate.loaded);

    if (isAuthorizing || isUserLoading || (isAuthorized || !isAffiliateLoaded)) {
        return <Loader />;
    }

    if (!isAuthorized) {
        return <Redirect to="login" />;
    }

    return <>{children}</>;
};

export default Protected;
