import {
    ICashierTransactionEntry,
    ITransactionEntry,
    IHandcashTransactionEntry, TransactionType,
    TransactionTypeId, HandcashTransactionTask,
} from "src/containers/Account/hooks/useTransactions/transactions.model";
import { CryptoCurrency } from "src/core/currency/currency.model";
import { ISODateTimeString } from "src/utils/type-utils";

export const mapTransactionTypeToTransactionTypeId = (type: TransactionType | "" | null | undefined): TransactionTypeId | null => {
    switch (type) {
        case TransactionType.Deposit: return TransactionTypeId.Deposit;
        case TransactionType.Withdrawal: return TransactionTypeId.Withdrawal;
    }

    return null;
}

export const mapTransactionTypeIdToTransactionType = (type: TransactionTypeId): TransactionType => {
    switch (type) {
        case TransactionTypeId.Deposit: return TransactionType.Deposit;
        case TransactionTypeId.Withdrawal: return TransactionType.Withdrawal;
        default: return null as never;
    }
}

export const normalizeTransactionTypeForHandcash = (type: TransactionType | TransactionTypeId): Exclude<HandcashTransactionTask, "PENALTY"> => {
    switch (type) {
        case TransactionType.Deposit:
        case TransactionTypeId.Deposit:
            return "DEPOSIT";
        case TransactionType.Withdrawal:
        case TransactionTypeId.Withdrawal:
            return "WITHDRAW";
    }
}

const formatDateTime = (timestamp: number | ISODateTimeString) => {
    const date = new Date(timestamp);

    const dateString = date.toLocaleDateString("en-GB", {
        timeZone: "UTC",
        year: "numeric",
        day: "2-digit",
        month: "2-digit",
    });
    const timeString = date.toLocaleTimeString("en-GB", {
        timeZone: "UTC",
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
    });

    return `${dateString} ${timeString}`;
}

export const mapCashierTransactionToTransaction = (dto: ICashierTransactionEntry): ITransactionEntry => {
    return {
        transactionId: dto.publicId,
        amount: dto.amount,
        currency: dto.currencyTicker,
        amountInUsd: dto.amountInUsd,
        timestampParam: dto.createTime,
        timeFormatted: formatDateTime(dto.createTime),
        status: dto.status,
        type: mapTransactionTypeIdToTransactionType(dto.type),
    }
}

export const mapHandcashTransactionToTransaction = (dto: IHandcashTransactionEntry): ITransactionEntry => {
    return {
        transactionId: dto.txid,
        amount: dto.amount,
        currency: CryptoCurrency.BSV,
        timestampParam: dto.timestamp,
        timeFormatted: formatDateTime(dto.timestamp),
        status: dto.status,
        type: dto.task,
    }
}