import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./styles.scss";

import IconDouble from "src/games/Blackjack/betting/Actions/assets/double.svg?inline";
import IconSplit from "src/games/Blackjack/betting/Actions/assets/split.svg?inline";
import IconYes from "src/games/Blackjack/betting/Actions/assets/accept.svg?inline";

const cx = classNames.bind(styles);

export default function PlayerPick({ side, subActions }) {
    const tags = [];

    if (subActions.double) {
        tags.push(
            <span className={cx("tag")} key="double">
        <IconDouble />
      </span>,
        );
    }
    if (subActions.even_money) {
        tags.push(
            <span className={cx("tag", "xsmall", "black", "text")} key="evenMoney">
        EM <IconYes />
      </span>,
        );
    }
    if (subActions.split) {
        tags.push(
            <span className={cx("tag")} key="split">
        <IconSplit />
      </span>,
        );
    }
    if (subActions.insurance) {
        tags.push(
            <span className={cx("tag", "xsmall", "black", "text")} key="insurance">
        IN. <IconYes />
      </span>,
        );
    }

    let formattedSide = side;
    if (typeof side === "string") {
        formattedSide = formattedSide === "BJ" ? "Blackjack" : `${side[0].toUpperCase()}${side.slice(1)}`;
    }
    return (
        <div className={cx("player-pick")}>
            <span className={cx("small")}>{formattedSide}</span>
            {tags}
        </div>
    );
}
PlayerPick.propTypes = {
    side: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    subActions: PropTypes.object,
};
PlayerPick.defaultProps = {
    subActions: {},
};
