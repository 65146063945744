import { QueryKey } from "@tanstack/react-query";
import { RefObj } from "@ntropy/utils/src/browser/browser-types";
import queryHelpers from "src/core/query/query-helpers";
import { useRefValue } from "@ntropy/hooks/src/state/useRefValue";
import { useSignalREventHandler } from "src/core/signalr/hooks/useSignalREventHandler";
import { SignalREvent } from "src/core/signalr/signalr.model";

interface IUseQuerySignalRUpdateOptions {
    enabled?: boolean
}

type QuerySignalRUpdateHandler<TEventData, TQueryFnData> =
    (eventData: TEventData, queryData: TQueryFnData, event: SignalREvent) => TQueryFnData | void | Promise<TQueryFnData | void>

const getEventHandler = <TEventData, TQueryFnData, TaggedQueryKey extends QueryKey = QueryKey>(
    subscriptionHandler: RefObj<QuerySignalRUpdateHandler<TEventData, TQueryFnData>>,
    queryKey: RefObj<TaggedQueryKey>,
    event: SignalREvent,
) => {
    return async (eventData: TEventData) => {
        await queryHelpers.update<TQueryFnData>(queryKey.current!, queryData => subscriptionHandler.current!(eventData, queryData, event));
    }
}

export function useQuerySignalRUpdate<TEventData, TQueryFnData, TaggedQueryKey extends QueryKey = QueryKey>(
    queryKey: TaggedQueryKey,
    event: SignalREvent,
    handler: QuerySignalRUpdateHandler<TEventData, TQueryFnData>,
    { enabled = true }: IUseQuerySignalRUpdateOptions = {},
) {
    const queryKeyRef = useRefValue(queryKey);

    const handlerRef = useRefValue(handler);
    const handlerMapped = getEventHandler(handlerRef, queryKeyRef, event);

    useSignalREventHandler<TEventData>(event, handlerMapped, { enabled });
}