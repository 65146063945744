import "src/assets/css/main.css";
import "src/assets/scss/style.scss";

import { FC, useEffect } from "react";
import Helmet from "react-helmet";
import { renderRoutes, RouteConfig } from "react-router-config";
import { getAccessAvailable } from "src/redux/modules/info";
import Toast from "src/components/toast/Toast";
import config from "src/config";
import Maintenance from "src/static/Maintenance";
import tidio from "src/helpers/tidio";
import { useAppDispatch } from "src/redux/reducer";
import { useMaintenance } from "src/core/maintenance/hooks/useMaintenance";
import { useAsync } from "@ntropy/hooks";
import { useMaintenanceSocket } from "src/core/maintenance/hooks/useMaintenanceSocket";
import { useSleepModeCheck } from "src/containers/App/hooks/useSleepModeCheck";
import { useScrollTo } from "src/containers/App/hooks/useScrollTo";
import { useAffiliateCampaignSend } from "src/core/affiliate/hooks/useAffiliateCampaignSend";
import { useRemovePlaceholder } from "src/containers/App/hooks/useRemovePlaceholder";
import { useLoadHandcashUserWithAddons } from "src/core/auth/hooks/useLoadHandcashUserWithAddons";
import ModalsLayer from "src/components/modal/ModalsLayer";
import { withSignalRClientProvider } from "src/core/signalr/hooks/useSignalRClient";

interface IAppProps {
    route: { routes: RouteConfig[] },
}

let tidioInitialized = false;

const App: FC<IAppProps> = ({ route }) => {
    const maintenance = useMaintenance();
    const dispatch = useAppDispatch();

    useRemovePlaceholder();
    useMaintenanceSocket();
    useScrollTo();
    useSleepModeCheck({ reload: false });
    useAffiliateCampaignSend();

    useLoadHandcashUserWithAddons();

    useEffect(() => {
        if (!window.__PRERENDER_INJECTED && !tidioInitialized) {
            tidioInitialized = true;
            tidio();
        }
    }, []);

    useAsync(async () => {
        try {
            await dispatch(getAccessAvailable());
        } catch (e) {
            console.error("Error when getAccessAvailable", e);
        }
    }, [])

    if (maintenance) {
        return <Maintenance site />;
    }

    return (
        <div>
            <Helmet {...config.app.head} />
            {renderRoutes(route.routes)}
            <ModalsLayer />
            <Toast />
        </div>
    );
}

export default withSignalRClientProvider(App);
