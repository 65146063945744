import { CasinoGameCategory } from "src/core/casino/casino.model";
import IconGames from "src/assets/img/games.svg?inline";
import IconSlots from "src/assets/img/slots.svg?inline";
import IconTableGames from "src/assets/img/table-games.svg?inline";

export const gameSections = [
    {
        title: "In-house",
        icon: <IconGames />,
        category: CasinoGameCategory.InHouse,
        viewAll: false,
        limitSize: () => 10,
        isEmpty: () => false,
    },
    {
        title: "Slots",
        icon: <IconSlots />,
        category: CasinoGameCategory.Slots,
        viewAll: true,
        limitSize: (isDesktopOnly: boolean) => (isDesktopOnly ? 10 : 9),
        isEmpty: (gamesLength: number) => !gamesLength,
    },
    {
        title: "Table",
        icon: <IconTableGames />,
        category: CasinoGameCategory.Table,
        viewAll: true,
        limitSize: (isDesktopOnly: boolean) => (isDesktopOnly ? 10 : 9),
        isEmpty: (gamesLength: number) => !gamesLength,
    },
];