export const COOKIE = {
    _ARE_YOU_ADULT: "are-you-adult",
    _LAST_LOCATION: "last-location",
    _MONEY_BUTTON_USER_ID_HASH: "money-button-user-id-hash",
};

export const ALL = "all";

export const TURTLE = {
    _RED: "R",
    _YELLOW: "Y",
    _BLUE: "B",
};

export const LADDER = {
    _LEFT: "L",
    _RIGHT: "R",
};
