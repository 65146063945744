import { Card, CardFooter, CardHeader, CardTitle, CardContent } from "src/components/card/Card";

interface ISimpleCardProps {
    title: string;
    description: string;
    callToAction?: React.ReactElement;
}

export const SimpleCard = ({ callToAction, title, description }: ISimpleCardProps) => {
    return (
        <Card className="text-card bg-brand-primary-500 border-0 p-8 lg:p-10 w-full">
            <CardHeader>
                <CardTitle className="text-[28px]">{title}</CardTitle>
            </CardHeader>
            <CardContent className="min-h-40">
                <p className="text-sm md:text-lg">{description}</p>
            </CardContent>
            {!!callToAction &&
                <CardFooter>
                    {callToAction}
                </CardFooter>
            }
        </Card>
    )
}