import { string, ValidationError } from "yup";
import { useMemo, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useDebounced } from "@ntropy/hooks/src/callback/useDebounced";
import { useWalletProvider } from "src/core/wallet/hooks/useWalletProvider";
import { WalletProvider, isSupportedWalletProvider } from "src/core/wallet/wallet.model";
import { axiosInstance } from "src/core/http/axios-instance";
import { Endpoint } from "src/core/http/endpoint";
import { useCurrentUsedApi } from "src/core/application/hooks/useCurrentUsedApi";

const validationSchema = string()
    .min(4, "Username is too short")
    .max(22, "Username is too long")
    .matches(/^[A-Za-z0-9+.]*$/, "Username can only contain alphanumeric characters and periods")
    .trim();

type CvApiValidationResult = "used" | "available" | "invalid_text";

export function useValidateNickname({
    username, newNickname, enabled = true,
}: { username: string, newNickname: string, enabled?: boolean }) {
    const validationMessage = useMemo(() => {
        if (!enabled) {
            return;
        }

        try {
            validationSchema.validateSync(newNickname);
        } catch (e) {
            if (e instanceof ValidationError) {
                return e.message;
            }
        }
    }, [newNickname, enabled]);

    const [walletProvider] = useWalletProvider();

    const {data, mutateAsync, reset} = useMutation({
        mutationFn: async (newNickname: string) => {
            if (validationMessage || !enabled || username === newNickname || !isSupportedWalletProvider(walletProvider)) {
                return;
            }

            let result: CvApiValidationResult;

            switch (walletProvider) {
                case WalletProvider.WalletConnect:
                    const nicknameUnique = await axiosInstance.get<boolean>(Endpoint.UserVerifyNickname(newNickname));

                    result = nicknameUnique ? "available" : "used";
                    break;
                case WalletProvider.HandCash:
                    const { data } = await axiosInstance
                        .post<{ data: { result: CvApiValidationResult } }>("/v1/mypage/check/nickname", { nickname: newNickname });

                    result = data.result;
                    break;
            }

            let errorMessage: string | undefined;
            let successMessage: string | undefined;

            switch (result) {
                case "invalid_text":
                    errorMessage = "This username is not valid";
                    break;
                case "used":
                    errorMessage = "This username is not available";
                    break;
                case "available":
                    successMessage = "Username available";
                    break;
            }

            return {
                errorMessage,
                successMessage,
            }
        },
    })

    const validate = useDebounced(mutateAsync, 400);

    useEffect(() => {
        if (!enabled) {
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabled]);

    useEffect(() => {
        if (validationMessage || !enabled) {
            return;
        }

        reset();
        validate(newNickname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newNickname]);

    console.log({ validationMessage, ...data ?? {} });

    return {
        errorMessage: validationMessage || data?.errorMessage,
        successMessage: data?.successMessage,
    }
}