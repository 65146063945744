import styled from "styled-components";
import { Caption } from "src/affiliate/styled/typography.styled";
import { IRelativeLinkProps } from "src/components/relative-link/types";
import RelativeLink from "src/components/relative-link/RelativeLink";

export const StyledPaidAffiliate = styled.h3`
    color: #22096c;
    margin: 0 0 8px 0;
    @media only screen and (max-width: 568px) {
        font-size: 20px;
        line-height: 28px;
    }
`;

export const StyledGetYourProfit = styled.h1`
    color: #22096c;
    margin: 0;
    @media only screen and (max-width: 568px) {
        font-size: 34px;
        line-height: 42px;
    }
`;

export const StyledCaption = styled(Caption)`
    color: #1f0d53;
    margin: 18px 0 32px 0;
    @media only screen and (max-width: 568px) {
        margin: 16px 0 24px 0;
    }
`;

interface IStyledButtonProps extends IRelativeLinkProps {
    margin?: string;
    backgroundColor?: string;
    borderColor?: string;
    hoverColor?: string;
    hoverBackgroundColor?: string;
    mobileMargin?: string;
}

export const StyledButton = styled(
    ({
        width, margin, backgroundColor, borderColor, color, hoverColor, hoverBackgroundColor, ...rest
    }: IStyledButtonProps) => (
        <RelativeLink {...rest} />
    ),
)`
    width: ${({ width = "240px" }) => width};
    height: 50px;
    border-radius: 4px;
    margin: ${({ margin }) => margin};
    background-color: ${({ backgroundColor = "#ff2976" }) => backgroundColor};
    border: ${({ borderColor }) => `solid 1px ${borderColor}`};
    color: ${({ color = "#ffffff" }) => color};
    padding: 13px;
    text-align: center;
    @media only screen and (max-width: 568px) {
        width: 100%;
        margin: ${({ mobileMargin }) => mobileMargin};
    }

    link {
        text-align: center;
        text-decoration: none;
    }

    &:hover {
        color: ${({ hoverColor = "#fff" }) => hoverColor};
        background-color: ${({ hoverBackgroundColor = "#d10a52" }) => hoverBackgroundColor};
    }

    transition: all 0.3s ease;
`;