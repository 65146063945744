import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import HistoryTableRow from "src/containers/Account/History/HistoryTableRow";
import TableWrapper, { ITableColumn } from "src/components/table-wrapper/TableWrapper";
import { isExternalGameCategory, GameCode } from "src/core/casino/casino.model";
import { GameHistoryStatus } from "src/containers/Account/hooks/useGameHistory/game-history.model";
import { useGameHistory } from "src/containers/Account/hooks/useGameHistory/useGameHistory";
import { useWalletProvider } from "src/core/wallet/hooks/useWalletProvider";
import { WalletProvider } from "src/core/wallet/wallet.model";

const cx = classNames.bind(styles);

interface IHistoryTableProps {
    gameCode: GameCode;
    betStatus?: GameHistoryStatus | "";
}

const HistoryTable = ({ gameCode, betStatus }: IHistoryTableProps) => {
    const {
        data = { pages: [] },
        fetchNextPage,
        hasNextPage,
        isFetching,
        isLoading,
    } = useGameHistory({ gameCode, betStatus });
    const [walletProvider] = useWalletProvider();

    const flatData = data.pages.flat();

    let columns: ITableColumn[] = [
        { label: "Round ID" },
        { label: "Bet", className: cx("bet-amount") },
        { label: "Multiplier" },
        { label: "My Pick", hideMobile: true },
        { label: "Result", hideMobile: true },
        { label: "Payout" },
    ];

    if (isExternalGameCategory(gameCode)) {
        columns = [
            { label: "Round ID", hideMobile: true },
            { label: "Game" },
            { label: "Bet", className: cx("bet-amount") },
            { label: "Multiplier", hideMobile: true },
            { label: "Payout" },
        ];
    }

    return (
        <TableWrapper
            isLoading={isLoading}
            columns={columns}
            className={cx("history-table")}
            infiniteScrollProps={{
                loadMore: () => fetchNextPage(),
                hasMore: !isFetching && hasNextPage,
            }}
            emptyPlaceholder={!flatData.length ? "You do not have any history" : ""}
        >
            {flatData.map(entry => (
                    <HistoryTableRow
                        key={`${gameCode ?? entry.gameCode}-${entry.roundId}-${entry.id}`}
                        gameCode={walletProvider === WalletProvider.WalletConnect ? entry.gameCode! : gameCode}
                        entry={entry}
                    />
                ),
            )}
        </TableWrapper>
    );
};

export default HistoryTable;
