import { FC } from "react";
import { CryptoCurrency, FiatCurrency, isFiatCurrency, isCryptoCurrency } from "src/core/currency/currency.model";
import CurrencyIcon from "src/components/currency-icon/CurrencyIcon";
import cn from "classnames";
import { AllowedTags } from "@ntropy/utils/src/html-utils";

interface ICurrencyAmountProps {
    amount: number | string
    currency: CryptoCurrency | FiatCurrency | null | undefined
    tag?: Exclude<AllowedTags, "br">
    className?: string
    amountClassName?: string
    cryptoIconClassName?: string
}

const CurrencyAmount: FC<ICurrencyAmountProps> = ({ tag: Tag = "div", amount, currency, className, cryptoIconClassName, amountClassName }) => {
    return (
        <Tag className={cn("inline-flex items-center justify-center", className)}>
            <span className={cn("text-md", amountClassName)}>{isFiatCurrency(currency) && <>$&nbsp;</>}{amount}</span>
            {isCryptoCurrency(currency) && <CurrencyIcon currency={currency} className={cn("h-[24px] ml-[8px]", cryptoIconClassName)} />}
        </Tag>
    );
};

export default CurrencyAmount;