import { games } from "src/games/config";
import { wheelSections } from "../../../games/Wheel/config";
import { InHouseGame } from "src/core/casino/casino.model";

export default function useFinalResult(gameCode, resultNumber) {
    switch (gameCode) {
        case InHouseGame.Roulette:
            return resultNumber;
        case InHouseGame.Dice:
            return resultNumber;
        case InHouseGame.Coinflip:
            return resultNumber === "0" ? "Heads" : "Tails";
        case InHouseGame.CfBattle:
            return resultNumber === "0" ? "Heads" : "Tails";
        case InHouseGame.Wheel:
            if (resultNumber) return games.wheel.formatResult(wheelSections[resultNumber]);
            break;
        case InHouseGame.Turtle:
            if (resultNumber === "0") {
                return "Yellow, Red, Blue";
            }
            if (resultNumber === "1") {
                return "Yellow, Blue, Red";
            }
            if (resultNumber === "2") {
                return "Red, Yellow, Blue";
            }
            if (resultNumber === "3") {
                return "Red, Blue, Yellow";
            }
            if (resultNumber === "4") {
                return "Blue, Yellow, Red";
            }
            if (resultNumber === "5") {
                return "Blue, Red, Yellow";
            }
            break;
        case InHouseGame.Ladder:
            if (resultNumber === "0" || resultNumber === "3") {
                return "Left";
            }
            if (resultNumber === "1" || resultNumber === "2") {
                return "Right";
            }
            break;
        default:
            return "";
    }
}
