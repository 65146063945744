import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import { useMobileOnly } from "src/helpers/responsive";
import { categories } from "src/components/games-list/constants/categories-list";
import useGamesList from "src/games/hooks/useGamesList";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { SkeletonCard } from "src/components/skeleton-card/SkeletonCard";
import GameThumbnail from "src/components/game-thumbnail/GameThumbnail";
import { CasinoGameCategory } from "src/core/casino/casino.model";
import { useCurrentUsedApi } from "src/core/application/hooks/useCurrentUsedApi";
import { Button } from "src/components/shadcn-button/Button";

const cx = classNames.bind(styles);

export default function GamesList() {
    const isMobileOnly = useMobileOnly();
    const params = useParams<{ category: CasinoGameCategory | undefined }>();

    const gameCategoryFromUrl = (params?.category || "") as CasinoGameCategory | ""
    const { games, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useGamesList(gameCategoryFromUrl);
    const { shouldUseHandcash } = useCurrentUsedApi()

    const getThumbnailSkeletons = (amount = 30) => Array.from({ length: amount }).map((_, i) => <SkeletonCard key={i} />);

    return (
        <>
            <div className={cx("category-selection")}>
                {categories.map(({ name, getTitle, icon }) => (
                    <div key={name}>
                        <Link
                            to={`/games/${name}`}
                            className={cx({ selected: (name === "" && gameCategoryFromUrl === "") || gameCategoryFromUrl === name })}
                        >
                            {icon}
                        </Link>
                        <span className={cx("small")}>{getTitle(isMobileOnly)}</span>
                    </div>
                ))}
            </div>
            <div className={cx("games")}>
                {isLoading ? getThumbnailSkeletons() : games.map(({
                    url, provider, thumbnailSrc, title, isDisabled,
                }) => (
                    <GameThumbnail key={title} url={url} provider={provider} thumbnailSrc={thumbnailSrc} title={title} isDisabled={isDisabled} />
                ))}
                {isFetchingNextPage ? getThumbnailSkeletons() : null}
            </div>

            {!shouldUseHandcash &&
                <div className="flex justify-center items-center mt-8">
                    <Button size="lg" className="text-base" disabled={isFetchingNextPage || !hasNextPage} onClick={() => fetchNextPage()}>
                        Load more games
                    </Button>
                </div>
            }
        </>
    );
}