import { useState, useEffect } from "react";
import classNames from "classnames/bind";
import Modal from "src/components/modal/Modal";
import styles from "./assets/styles.scss";
import Input from "src/components/input/Input";
import Button from "src/components/button/Button";
import { useChangeNickname } from "src/containers/Account/hooks/useChangeNickname";
import { useValidateNickname } from "src/containers/Account/hooks/useValidateNickname";

const cx = classNames.bind(styles);


interface IChangeUsernameProps {
    username: string;
}

const ChangeUsername = ({ username }: IChangeUsernameProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [newUsername, setNewUsername] = useState(username);

    const {
        errorMessage, successMessage,
    } = useValidateNickname({ username, newNickname: newUsername, enabled: isOpen });

    const [updateUsername, isPending] = useChangeNickname({
        onSuccess: () => setIsOpen(false),
    });

    // reset on modal close
    useEffect(() => {
        if (!isOpen) {
            setNewUsername(username);
        }
    }, [isOpen, username]);

    return (
        <>
            <button type="button" className={cx("xsmall")} onClick={() => setIsOpen(true)}>
                Change
            </button>
            <Modal
                isOpen={isOpen}
                header="Change Username"
                onClose={() => !isPending && setIsOpen(false)}
                dark
                backgroundColor="#1F0D53"
                className={cx("change-username-modal")}
                footerContent={(
                    <Button
                        className={cx("save-button")}
                        onClick={() => updateUsername(newUsername)}
                        isDisabled={!successMessage}
                        isLoading={isPending}
                    >
                        Save
                    </Button>
                )}
            >
                <Input
                    className={cx("input-container")}
                    value={newUsername}
                    onChange={newValue => setNewUsername(newValue.toString())}
                    error={errorMessage}
                    success={successMessage}
                />
                <p className={cx("small")}>Username must be no longer than 22 characters.</p>
                <p className={cx("small")}>Username can only contain alphanumeric characters (A–Z, a-z, 0–9) and periods (".").</p>
            </Modal>
        </>
    );
};

export default ChangeUsername;
