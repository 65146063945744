import { axiosInstance } from "src/core/http/axios-instance";
import { Endpoint } from "src/core/http/endpoint";
import config from "src/config";
import {
    ICashierTransactionEntry,
    IHandcashTransactionsResponse,
    TransactionStatus,
    TransactionType,
} from "src/containers/Account/hooks/useTransactions/transactions.model";
import {
    mapCashierTransactionToTransaction,
    mapHandcashTransactionToTransaction,
    mapTransactionTypeToTransactionTypeId, normalizeTransactionTypeForHandcash,
} from "src/containers/Account/hooks/useTransactions/transactions.mappers";
import { ISODateTimeString } from "src/utils/type-utils";

export async function getHandcashTransactions(
    type: TransactionType,
    status: TransactionStatus | "",
    offset: number | null,
) {
    if (status === TransactionStatus.Error) {
        console.error("Handcash transactions endpoint do not support TransactionStatus.Error");
        return [];
    }
    const { data } = await axiosInstance.get<IHandcashTransactionsResponse>(Endpoint.HandcashTransactionsHistory, {
        params: {
            asset: config.asset,
            count: 30,
            offset,
            task: normalizeTransactionTypeForHandcash(type),
            status,
        },
    })

    return data.map(mapHandcashTransactionToTransaction);
}

export async function getCashierTransactions(
    type: TransactionType,
    status: TransactionStatus | "",
    lastCreateTime: ISODateTimeString | null,
) {
    const data = await axiosInstance.get<ICashierTransactionEntry[]>(Endpoint.TransactionHistory, {
        params: {
            pageSize: 30,
            lastCreateTime,
            type: mapTransactionTypeToTransactionTypeId(type),
            status: status || null,
        },
    });

    return data.map(mapCashierTransactionToTransaction);
}