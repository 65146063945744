import { enumToValues } from "@ntropy/utils/src/enum-utils";

export enum CryptoCurrency {
    ADA = "ADA",
    BCH = "BCH",
    BNB = "BNB",
    BTC = "BTC",
    BTG = "BTG",
    DASH = "DASH",
    DOGE = "DOGE",
    ETH = "ETH",
    LSK = "LSK",
    LTC = "LTC",
    TRX = "TRX",
    XRP = "XRP",
    USDT = "USDT",
    ZEC = "ZEC",
    USDC = "USDC",
    BUSD = "BUSD",
    /** Only used with Handcash */
    BSV = "BSV",
    PGP = "PGP",
}

const allCryptoCurrencies = enumToValues(CryptoCurrency, null);

export enum CryptoCurrencyName {
    ADA = "Cardano",
    BCH = "Bitcoin Cash",
    BNB = "Binance Coin",
    BTC = "Bitcoin",
    BTG = "Bitcoin Gold",
    DASH = "Dash",
    DOGE = "Dogecoin",
    ETH = "Ethereum",
    LSK = "Lisk",
    LTC = "Litecoin",
    TRX = "Tron",
    XRP = "Ripple",
    USDT = "Tether",
    ZEC = "Zcash",
    USDC = "USD Coin",
    BUSD = "Binance USD",
    /** Only used with Handcash */
    BSV = "Bitcoin SV",
    PGP = "Pretty Good Privacy",
}

export type HandcashCurrency = CryptoCurrency.BSV | CryptoCurrency.PGP;
export type WalletCurrency = Exclude<CryptoCurrency, HandcashCurrency>;

export interface ICurrencyAccount {
    currencyTicker: WalletCurrency
    balance: number
}

export enum Chain {
    Btc = "Btc",
    Eth = "Eth",
    Bnb = "Bnb",
    Sol = "Sol",
    Matic = "Matic",
    Ada = "Ada",
    Dot = "Dot",
    Xrp = "Xrp",
    Avax = "Avax",
}

export interface ICurrencyChains {
    currencyTicker: CryptoCurrency
    chains: Chain[]
}

export enum FiatCurrency {
    USD = "USD",
}

export const isCryptoCurrency = (value: string | null | undefined): value is CryptoCurrency => allCryptoCurrencies.includes(value as any);
export const isFiatCurrency = (value: string | null | undefined): value is FiatCurrency => value === FiatCurrency.USD;