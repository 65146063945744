import { useState } from "react";
import classNames from "classnames/bind";
import styles from "../assets/styles.scss";
import Detail from "src/components/detail/Detail";
import useRandomNumber from "src/containers/FairnessCalculation/hooks/useRandomNumber";
import useModulusBy from "src/containers/FairnessCalculation/hooks/useModulusBy";
import useResultNumber from "src/containers/FairnessCalculation/hooks/useResultNumber";
import useFinalResult from "src/containers/FairnessCalculation/hooks/useFinalResult";
import RandomNumber from "src/containers/FairnessCalculation/RandomNumber/RandomNumber";
import Result from "src/containers/FairnessCalculation/Result/Result";
import ResultTable from "src/containers/FairnessCalculation/ResultTable/ResultTable";
import { GameCode } from "src/core/casino/casino.model";

const cx = classNames.bind(styles);

interface IDefaultProps {
    data: {
        game_id: string;
        game_code: GameCode;
        seed_hash: string;
        next_game_id: string;
        next_seed_hash: string;
        prev_txids?: string[];
        txids?: string[];
    };
}

const Default = ({ data }: IDefaultProps) => {
    const {
        game_id: gameId,
        game_code: gameCode,
        seed_hash: seedHash,
        next_game_id: nextGameId,
        next_seed_hash: nextSeedHash,
        prev_txids,
        txids,
    } = data;

    const [txIds, setTxIds] = useState<string[]>(prev_txids || txids || []);

    const onTxIdChange = (index: number, value: string) => {
        const updatedTxIds = [...txIds];
        updatedTxIds[index] = value;
        setTxIds(updatedTxIds);
    };

    const randomNumber = useRandomNumber(seedHash, txIds);
    const modulusBy = useModulusBy(gameCode);
    const resultNumber = useResultNumber(randomNumber, modulusBy);
    const finalResult = useFinalResult(gameCode, resultNumber);

    return (
        <div>
            <Detail
                contentClassName={cx("small")}
                className={cx("detail")}
                label={`Next Round Seed (${nextGameId || "-"})`}
                content={nextSeedHash}
                copiable={!!nextSeedHash}
            />
            <Detail
                contentClassName={cx("small")}
                className={cx("detail")}
                label={`Current Round Seed (${gameId || "-"})`}
                content={seedHash}
                copiable={!!seedHash}
            />
            <RandomNumber txIds={txIds} seedHash={seedHash} onTxIdChange={onTxIdChange} />
            <Result modulusBy={modulusBy} result={resultNumber} randomNumber={randomNumber} />
            <Detail
                contentClassName={cx("detail-content")}
                className={cx("detail")}
                label="Final Result"
                content={finalResult}
            />
            <ResultTable game={gameCode} />
        </div>
    );
};

export default Default;
