import { PickByType, OmitByType } from "@ntropy/utils";
import { CryptoCurrency } from "src/core/currency/currency.model";

export const Endpoint = {
    AuthMeV2: "/auth/v2/me",
    Accounts: "/cashier/accounts",
    Logout: "/cashier/auth/logout",
    Rates: "/cashier/rates",
    CryptoChains: "/cashier/currencies/crypto/chains",
    DepositAddress: (currency: CryptoCurrency) => `/cashier/accounts/${currency.toLowerCase()}/depositAddress` as const,
    Withdraw: (currency: CryptoCurrency) => `/cashier/accounts/${currency.toLowerCase()}/withdraw` as const,
    AuthChallenge: "/cashier/auth/challenge",
    AuthChallengeVerify: "/cashier/auth/challenge/verify",
    UserGameHistory: "/cashier/user/gameHistory",
    UserInfo: "/cashier/user/profile",
    UserNicknameChange: "/cashier/user/nickname",
    UserVerifyNickname: (nickname: string) => `/cashier/user/verifyNickname/${nickname}`,
    UserAvatarChange: "/cashier/user/avatar",
    UserRandomizeInfoTest: "/cashier/user/randomizeInfo", // dev only
    UserStatistics: "/cashier/user/statistics", // dev only
    GameHistory: "/cashier/user/gameHistory",
    TransactionHistory: "/cashier/user/transactionHistory",
    CashierGamesList: "/cashier/softswiss/games",
    HandcashGamesList: "/v1/softswiss/gamelist",
    CashierSoftswissSession: "/cashier/softswiss/session",
    CashierSoftswissDemo: "/cashier/softswiss/demo",
    HandcashMyPoints: "/v1/user/points",
    HandcashGameHistory: "/v1/mypage/gamehistory",
    HandcashTransactionsHistory: "/v1/deposit/history", // also withdrawals
} as const;

type endpointType = typeof Endpoint;
type FnEndpoints = OmitByType<endpointType, string>
type StringEndpoints = PickByType<endpointType, string>

export type ApiEndpoint = StringEndpoints[keyof StringEndpoints] | ReturnType<FnEndpoints[keyof FnEndpoints]>