import {
    IHandcashGameHistoryEntry,
    GameHistoryEntry,
    ICashierGameHistoryEntry,
    GameHistoryStatus,
} from "src/containers/Account/hooks/useGameHistory/game-history.model";
import { FiatCurrency } from "src/core/currency/currency.model";

export const mapHandcashGameHistoryEntry = (dto: IHandcashGameHistoryEntry): GameHistoryEntry => {
    return {
        id: dto.id,
        roundId: dto.game_id,
        gameName: dto.game_name,
        betAmount: dto.balance,
        multiplier: dto.winning_rate,
        payout: dto.payout_balance,
        status: dto.status === "waiting" ? GameHistoryStatus.Pending : dto.status,
        currency: dto.asset,
        isWin: dto.winner_player === "Y",
        result: dto.game_result,
        area: dto.area,
        transaction: dto.txid || dto.txid_for_url || dto.payout_txid ? {
            txId: dto.txid,
            txIdForUrl: dto.txid_for_url,
            payoutTxId: dto.payout_txid,
        } : undefined,
    }
}

export const mapCashierGameHistoryEntry = (dto: ICashierGameHistoryEntry): GameHistoryEntry => {
    return {
        id: dto.wagerId,
        roundId: dto.gameId,
        gameName: dto.title,
        betAmount: dto.bet,
        payout: dto.payout,
        multiplier: dto.multiplier,
        gameCode: dto.category,
        currency: FiatCurrency.USD,
        isWin: dto.payout > 0,
        transaction: {
            transactionId: dto.transactionId,
            payoutTransactionId: dto.payoutTransactionId,
        },
    }
}