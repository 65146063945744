import { Ref, ReactNode } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames/bind";
import { useTransition, animated } from "@react-spring/web";
import styles from "./assets/styles.scss";
import Backdrop from "src/components/backdrop/Backdrop";
import IconClose from "./assets/close.svg?inline";

const cx = classNames.bind(styles);

interface IModalProps {
    isOpen: boolean;
    onClose?(): void;
    header: string | React.ReactElement | null;
    children: ReactNode;
    dark?: boolean;
    bodyRef?: Ref<HTMLDivElement> | null;
    backgroundColor?: string;
    fullMobileWidth?: boolean | null;
    className?: string | null;
    modalClassName?: string | null;
    footerContent?: React.ReactElement | null;
    unmountOnClose?: boolean;
}

export default function Modal({
    isOpen = false,
    onClose = () => {
    },
    header = null,
    children = null,
    dark = false,
    fullMobileWidth = null,
    className = null,
    bodyRef = null,
    backgroundColor,
    modalClassName = null,
    footerContent = null,
    unmountOnClose = true,
}: IModalProps) {
    const transition = useTransition(isOpen, {
        from: { opacity: 0, scale: 0 },
        enter: { opacity: 1, scale: 1 },
        leave: { opacity: 0, scale: 0 },
        expires: unmountOnClose,
    });

    return createPortal(
        <>
            <Backdrop visible={isOpen} onClick={onClose} />
            {transition(
                (style, item) => item && (
                    <animated.div
                        style={style}
                        className={cx("modal", { dark, "full-mobile-width": fullMobileWidth })}
                        onClick={onClose}
                        role="dialog"
                    >
                        <div
                            className={cx("modal-content", modalClassName)}
                            onClick={e => e.stopPropagation()}
                            role="dialog"
                            style={{ backgroundColor }}
                        >
                            {typeof header === "string" ? (
                                <div className="header">
                                    <h3 className={cx("text")}>{header}</h3>
                                    <button type="button" aria-label="Close" className={cx("close-button")} onClick={onClose}>
                                        <IconClose />
                                    </button>
                                </div>
                            ) : (
                                header
                            )}
                            <div className={cx("body", className)} ref={bodyRef}>
                                {children}
                            </div>
                            {footerContent}
                        </div>
                    </animated.div>
                )
            )}
        </>,
        document.body
    );
}
