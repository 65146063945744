import { ComponentType, useState, useEffect, createContext, useContext } from "react";

import SignalRClient, { SocketConnectionStatus } from "src/core/signalr/signalr-client";
import { useAuthorizedState } from "src/core/auth/hooks/useAuthorizedState";
import { WalletProvider } from "src/core/wallet/wallet.model";
interface ISignalRClientContext {
    connectionStatus: SocketConnectionStatus
    client: SignalRClient
}

const SignalRClientContext = createContext<ISignalRClientContext>({
    connectionStatus: SocketConnectionStatus.Connecting,
    client: null!,
})

export const withSignalRClientProvider = <P extends object = object>(InnerComponent: ComponentType<P>) => {
    const signalRClient = new SignalRClient();

    // eslint-disable-next-line react/display-name
    return (props: P) => {
        const { isAuthorized } = useAuthorizedState(WalletProvider.WalletConnect);
        const [connectionStatus, setConnectionStatus] = useState(signalRClient.connectionStatus);

        useEffect(() => {
            if (!isAuthorized) {
                return;
            }

            signalRClient.start();

            return () => {
                signalRClient.stop();
            }
        }, [isAuthorized])

        useEffect(() => {
            signalRClient.onConnectionStatus(setConnectionStatus);

            return () => {
                signalRClient.offConnectionStatus(setConnectionStatus);
            }
        }, []);

        return (
            <SignalRClientContext.Provider value={{ connectionStatus, client: signalRClient }}>
                <InnerComponent {...props} />
            </SignalRClientContext.Provider>
        )
    }
}

export function useSignalRClient(): ISignalRClientContext {
    return useContext(SignalRClientContext);
}